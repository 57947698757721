import React, { Component } from 'react';
import one from '../4-01.png';
import two from '../2-01.png';
import three from '../1-01.png';
import four from '../3-01.png';
class Guarantee extends Component {
    render(){
        return (
            <div class="container-fluid" style={{paddingTop: 20}}>
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12 cardFeature guaranteeFeature">
                        <img class="card-img-top partimage" src={one} alt="Card image cap"/>
                        <div class="card-body">
                            <h4 style={{color: '#fff',fontWeight: '400'}}>'A' Grade Quality Used OEM Parts</h4>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 cardFeature guaranteeFeature">
                    <img class="card-img-top partimage" src={two} alt="Card image cap"/>
                    <div class="card-body">
                        <h4 style={{color: '#fff',fontWeight: '400'}}>Standard 30 Days Warranty</h4>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 cardFeature guaranteeFeature">
                    <img class="card-img-top partimage" src={three} alt="Card image cap"/>
                    <div class="card-body">
                        <h4 style={{color: '#fff',fontWeight: '400'}}>Your Best Price Guaranteed</h4>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 cardFeature ">
                    <img class="card-img-top partimage" src={four} alt="Card image cap"/>
                    <div class="card-body">
                        <h4 style={{color: '#fff',fontWeight: '400'}}>Dedicated Customer Service</h4>
                    </div>
                    </div>
                </div>
                </div>
        )
    }
}

export default Guarantee;