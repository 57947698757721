import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Dodge from '../../images/dodgespindle.jpg';
import gif from '../btr-gif.gif';
class DodgeSpindleManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Dodge',
            makeid : 292,
            selectedpartid : 1,
            selectedpartname : 'Spindle (Front)',
            status : true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Dodge'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Dodge Spindle</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Dodge Spindles in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Dodge Spindles                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Dodge Spindle</u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Dodge} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Road Tested, Performance checked and quality assured Used Dodge Spindle
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Dodge models
                                                    </h6>
                                                    <br></br>
                                                    <h6><strong>Buy Quality Dodge Spindle Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts has high quality <u>used Dodge parts</u> to suit any vehicle. Whether you’re a
                                                        mechanic, garage owner or car dealer, or you drive an <u>Avenger</u>, <u>Challenger</u>, <u>Charger</u>, Dart, Durango,
                                                        <u>Journey</u>, Grand Caravan, <u>Ram truck</u> or other Dodge model, BackToRoad Auto Parts fully stocked
                                                        warehouses will exceed your auto parts needs.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Whether you value your Ramcharger, Rampage, Durango, Dakota, pickup, van or Sprinter, BackToRoad
                                                        Auto Parts has the used Dodge truck parts your vehicle requires to get the job done, payload after
                                                        payload. When you are determined to keep your Dodge truck running like new for as long as possible,
                                                        then get to know BackToRoad Auto Parts. Since 1940 we have offered quality used automotive parts and
                                                        have provided service that is legendary.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts provides major items like Used Transmissions, Used engines, Used
                                                        alternators,Used wheel,Used hoods, Used break systems and more. Every part is doubly inspected before
                                                        shipping, so you can enjoy peace of mind knowing that your used Dodge parts are durable, reliable and
                                                        safe.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Whatever make, model or year of your vehicle, BackToRoad Auto Parts provides reasonably priced, high
                                                        quality used Dodge parts. Our huge inventory allows customers to benefit from tremendous savings.
                                                        Enjoy affordable shipping, too. Quality used parts will swiftly arrive at your doorstep in pristine, fully
                                                        operational condition, ready for your Dodge car, SUV, van or pickup truck.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you’re unsure about certain products, BackToRoad Auto Parts staff of knowledgeable salespeople can
                                                        guide you towards which used Dodge parts to order. You won’t have to search high and low for that
                                                        special part or be at the mercy of pricey retailers or dealerships. In the rare event that a part is not in stock,
                                                        we can locate it for you and have it delivered anywhere you’d like.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts is your source for used Dodge parts. Since 1940, customers have turned to us for
                                                        all of their used Dodge parts and have benefitted unmatched customer service in the process. Check your
                                                        order status whenever you like, get quick returns and enjoy the security of knowing your used dodge parts
                                                        are backed by a One Month Standard Warranty. We provide a replacement part(s) or a full refund of the
                                                        purchase price plus shipping fees, so there’s no risk involved.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify',color:'rgb(218, 82, 82)'}}>
                                                    Get your used Dodge parts from a reliable auto parts seller today, call us at 1-800-608-3868
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default DodgeSpindleManagement;