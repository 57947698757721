import React, { Component } from 'react';
import './searchSide.css';
import axios from 'axios';
// import search from './search.png';
import {IP} from './constants';
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class SearchSide extends Component {
  constructor(props){
    super(props);
    this.state={
      yearval : [],
      makeval : [],
      modelval : [],
      partval : [] ,
      subpartval : [],
      partlocal : '',
      partLength : 50,
      showview: true,
      partvalsearch : '',
      searchLength : 0,
      selectedPartId : null,
      selectedPartVal : null,
      yearSelected : null,
      partSelected : null,
      makeSelected : null,
      modelSelected : null,
      subpartSelected : null,
      yearnew : null,
      modelnew : null,
      subPartsnew : null,
      changeCheck : 0,
      yearBlink : 0,
      make_name : null,
      make_id : null,
      makenew : null,
      makenews : null
    }
  }
  // componentWillMount(){
  //   window.location = 'about.html';

  // }
  componentDidMount () {
    this.getYear();
  }
  componentWillMount(){
    
  }
  subPartsNew (){
    axios.post(IP+'/subparts/'+localStorage.getItem('partid'))
    .then( (response) => {
      if (response.status === 200) {
        this.setState({
          subpartval : response.data,
          partSelected : 1
        });
      }
    })
    .catch( (error) => {
      console.log(error);
    });
  }
  getYear(e) {
    axios.post(IP+'/year')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            yearval : response.data,

          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getMake(e){
    // localStorage.setItem('year', e.target.value);
    this.setState({
      yearnew : e.target.value,
      yearBlink : 0
    });
    axios.post(IP+'/make/'+e.target.value)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            makeval : response.data,
            yearSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getModel(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('make', e.nativeEvent.target[index].text);
    this.setState({
      makenew : e.nativeEvent.target[index].text
    });
    axios.post(IP+'/model/'+e.target.value)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            modelval : response.data,
            makeSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getPart(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('model', e.nativeEvent.target[index].text);
    this.setState({
      modelnew : e.nativeEvent.target[index].text
    });
    axios.post(IP+'/parts/')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            partval : response.data,
            modelSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }
  componentDidUpdate(){

  }
  componentWillReceiveProps(nextProps){
    if(nextProps.partid){
      this.setState({
        selectedPartVal : nextProps.partname,
        yearBlink:1
      });
    }
    if(nextProps.view){
      if(nextProps.view=== 1){
        this.setState({
          yearBlink : 1
        });
      }
    }
    if(nextProps.makeid){
      
      this.setState({
        makenews: nextProps.makename,
        yearBlink : 1,
        makenew: nextProps.makename
      });
      // this.getYearByMake(nextProps.makename);
      axios.post(IP+'/getMakeSpecific')
      .then( (response) => {
          console.log('nioss',response)
        if (response.status === 200) {
          this.setState({
              makeval : response.data.rows
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
      axios.post(IP+'/getYearByMake/'+nextProps.makename)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            yearval : response.data,
            yearBlink : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
      axios.post(IP+'/model/'+nextProps.makeid)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            modelval : response.data,
            makeSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
    }
  }
  // getYearByMake(make){
  //   axios.post(IP+'/getYearByMake/'+make)
  //     .then( (response) => {
  //       if (response.status === 200) {
  //         this.setState({
  //           yearval : response.data,
  //           yearBlink : 1
  //         });
  //       }
  //     })
  //     .catch( (error) => {
  //       console.log(error);
  //     });
  // }
  getSubPart(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('part', e.nativeEvent.target[index].text);
    this.setState({
      selectedPartVal : e.nativeEvent.target[index].text,
      partSelected : 1,
      changeCheck : 1
    });
    // axios.post(IP+'/subparts/'+e.target.value)
    //   .then( (response) => {
    //     if (response.status === 200) {
    //       this.setState({
    //         subpartval : response.data,
    //         partSelected : 1
    //       });
    //     }
    //   })
    //   .catch( (error) => {
    //     console.log(error);
    //   });
  }

  // subParts(e){
  //   var index = e.nativeEvent.target.selectedIndex;
  //   // localStorage.setItem('subpart', e.nativeEvent.target[index].text);
  //   this.setState({
  //     subPartsnew : e.nativeEvent.target[index].text,
  //   });
  //   this.setState({
  //     subpartSelected : 1
  //   });
  // }

  // getProduct(){
  //   window.location = 'sendmail.html';
  // }

  // partFromList(){
  //   alert('sd')
  //   this.setState({
  //     yearBlink : 1
  //   }); 
  // }
  sendMailTo(){
    if(this.state.yearSelected === '' || this.state.yearSelected === null || this.state.yearSelected === undefined) {
      toast.info("Please Select Year", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } else if(this.state.makeSelected === '' || this.state.makeSelected === null || this.state.makeSelected === undefined) {
      toast.info("Please Select Make", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } else if(this.state.modelSelected === '' || this.state.modelSelected === null || this.state.modelSelected === undefined) {
      toast.info("Please Select Model", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } else if(this.state.selectedPartVal === '' || this.state.selectedPartVal === null || this.state.selectedPartVal === undefined) {
      toast.info("Please Select Part", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    }
  }
  render() {
    return (

                <div className="card">
                    <div className="card-header" style={{background:'#ffbf3c'}}>
                        <h4 className=" " style={{textAlign:'center', color:'black', fontWeight:'900'}}>Get a Free Quote Now!</h4>
                    </div>
                    <div className="card-body" style={{backgroundColor:"#6e7476"}}>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className={this.state.yearBlink === 1 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 1 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getMake.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} className="blink" >Year</option>
                                    {this.state.yearval.map((e, key) => {
                                        return <option key={key} value={e.year_name}>{e.year_name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className="form-control" style={{border: '2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getModel.bind(this)}>
                                {this.state.makenews === null || undefined ?
                                    <option disabled selected="selected" style={{color:'white'}} >Make</option>:
                                    <option >{this.state.makenews}</option>
                                     }
                                  {this.state.makeval.map((e, key) => {
                                      return <option key={key} value={e.id}>{e.make_name}</option>;
                                  })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className="form-control" style={{border: '2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getPart.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} >Model</option>
                                    {this.state.modelval.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.model_name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className="form-control" style={{border: '2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getSubPart.bind(this)}>
                                    {this.state.selectedPartVal === null ?
                                    <option disabled selected="selected" style={{color:'white'}} >Part Name</option>:
                                    <option >{this.state.selectedPartVal}</option>
                                     }
                                  {this.state.partval.map((e, key) => {
                                      return <option key={key} value={e.id}>{e.part_name}</option>;
                                  })}
                                </select>
                            </div>
                        </div>
                        {/* {this.state.subpartval.length === 0 ?
                          '' :
                          <div className="row">
                              <div className="col-sm-12 col-lg-12 col-md-12">
                                  <label style={{fontSize : 12}}>Sub Part(Optional)</label>
                                  <select className="form-control" onChange={this.subParts.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} >Choose Sub Part</option>
                                    {this.state.subpartval.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.sub_name}</option>;
                                    })}
                                  </select>
                              </div>
                          </div>
                        } */}
                        <div className="row">
                            <div className="col-sm-12 col-lg-12 col-md-12">
                            {this.state.yearSelected === null || this.state.makeSelected === null || this.state.modelSelected === null || !this.state.selectedPartVal
                                  ?
                                <button className="btn" onClick={this.sendMailTo.bind(this)}
                                style={{background:'#ffbf3c',color:'black',width:'100%',height:45,fontSize:20}}><strong>Find My Part Now !</strong></button>
                                 :
                                //  <button onClick={this.getProduct.bind(this)} className="btn"
                                // style={{background:'rgb(251, 208, 18)',color:'black'}}>GET YOUR PART</button>
                                <Link
                                className="btn" style={{background:'#ffbf3c',color:'black',width:'100%',height:45,fontSize:20}}
                               to={{ pathname: '/GetQuoteNow/', state: { make: this.state.makenew || this.state.makenews,model: this.state.modelnew,
                                 parts: this.state.changeCheck===0?this.props.partname : this.state.selectedPartVal, year:this.state.yearnew
                               } }}  ><strong>
                                 {/* <i class="fa fa-search"></i> */}
                                 Find My Part Now !
                                 </strong></Link>
                                 }

                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
    );
  }
}

export default SearchSide;