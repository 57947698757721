import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/bmwabs.jpg';
import gif from '../btr-gif.gif';
class BmwAbsManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'BMW',
            makeid : 75,
            selectedpartid : 1,
            selectedpartname : 'ABS',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'BMW',
            // status: false
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                    <div>
                        <Header/>
                        <div class="container-fluid">
                            <div class="row"
                                style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                    }}>
                                <div class="container">
                                    <h1 class="text-white"><b>Used BMW ABS Assembly / ABS Computer/ ABS module</b></h1>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        BMW ABS  in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the BMW ABS
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-3 col-sm-12 col-md-3">
                                    <br></br>
                                    <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                    <br></br>
                                </div>
                                <div className="col-lg-9 col-sm-9 col-md-9">
                                    <br></br>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <h4><strong><u>Quality Used BMW ABS Assembly </u></strong></h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                <img src={Ford} className="makeImage" />
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-6" >
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Performance checked and quality assured Used BMW ABS Assembly 
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all BMW models
                                                </h6>
                                                <br></br>
                                                <h6><strong>Buy Quality BMW ABS Online @ Best Price</strong></h6>
                                                <br></br>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    Get to know what makes BMW a trusted automobile brand
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    BMW automobile is a German MNC that is currently producing luxury automobiles and motorcycles and
                                                    has also produced aircraft engines until the year 1945.
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                            <h6 style={{textAlign: 'justify'}}>
                                                    The brand was founded in the year 1916 and is headquartered in Munich, Bavaria. The company produces
                                                    motor vehicles in Brazil, Germany, India, China, the United Kingdom, South Africa, and the United
                                                    States.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    Seeing to the kind of spread the company has in all the major countries of the word, it only makes sense
                                                    to seek out the authentic <strong>used BMW parts</strong> both online as well as offline.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <strong>Used BMW parts</strong> online: Authentic, <strong>OEM parts</strong> and accessories
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    BMW is commendable for its ancestry that has its roots in the precision application for aviation. BMW
                                                    has built vehicles that are the epitome of style, luxury, and drivability, which is a smart investment by any
                                                    measure. Even the best of the engineered machines need regular maintenance and the BMW is sadly no
                                                    exception.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    BackToRoad Auto Parts provides major items like used engines, transmissions, steering systems and
                                                    alternators, as well as smaller parts including used hoses, tires, hubcaps and more. Every part is doubly
                                                    inspected before shipping, so you can enjoy peace of mind knowing that your used BMW parts are
                                                    durable, reliable and safe.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                Our reputation for integrity, accuracy, honesty, and outstanding customer service is another reason why
                                                many do-it-yourself BMW owners choose us to be their <strong>used BMW parts</strong> seller.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    BackToRoad Auto Parts are foremost experts in providing fast, reasonably priced and optimally
                                                    functional used BMW parts. Our enormous inventory of small and large auto parts allows our customers
                                                    to benefit from tremendous savings. Enjoy quick and affordable worldwide shipping, you’re your auto
                                                    parts will arrive at your doorstep in pristine, fully operational condition, ready to be installed in your
                                                    BMW.
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <Guarantee/>
                        <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default BmwAbsManagement;