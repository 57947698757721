import React, { Component } from 'react';
import Header from '../scenes/components/header';
import SearchSide from '../scenes/searchSide';
import bg from './bgr.png';
import Footer from '../scenes/components/footer';
import engine from '../images/bgm-01.png';
import axios from 'axios';
import {IP} from './constants';
import Parts from '../scenes/components/home/parts';
import {
    BrowserRouter as Router,
    Route,Link
  
  } from 'react-router-dom';
import Models from './model';
class Makes extends Component {
    constructor(){
        super();
        this.state = {
            make_name : '',
            make_id : null,
            modelval : [],
            makes : [],
            makeid:null,
            makename:null,
            makelength:10,
            viewLook:0
        }
    }
    componentDidMount(){
        this.setState({
            make_name : this.props.location.state.getmakename,
            make_id : this.props.location.state.getmakeid
        });
        this.getModel(this.props.location.state.getmakeid || this.state.make_id);
        window.scrollTo(0, 0);
        this.getMakeSpecific();
    }
    enterMakes(id, name) {
        this.setState({
            makeid: id,
            makename: name
        });
        window.scrollTo(0, 0);
    }
    getModel(val){
        axios.post(IP+'/model/'+val)
        .then( (response) => {
            if (response.status === 200) {
            this.setState({
                modelval : response.data
            });
            }
        })
        .catch( (error) => {
            console.log(error);
        });
    }
    changeView() {
        this.setState({
            makelength: 50,
            viewLook: 1
        });
    }
    changeHide() {
        this.setState({
            makelength: 10,
            viewLook: 0
        });
    }
    getMakeSpecific(e) {
        axios.post(IP + '/getMakeSpecific')
            .then((response) => {
                console.log('nioss', response)
                if (response.status === 200) {
                    this.setState({
                        makes: response.data.rows
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="Makes">
            <Router>
            <div >
            {this.state.modelval.map((model,i) =>
                <Route exact path={`/make/model/${model.model_name}`} component={Models}/>
            )
            }
            </div>
            </Router>
                <Header />
                <div className="container-fluid">
                    <div className="row"
                        style={{
                            backgroundColor: '#DA5252', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center',
                            backgroundImage: "url(" + bg + ")", backgroundRepeat: 'repeat',
                            backgroundSize: 145
                        }}>
                        <div className="container">
                            <h1 className="text-white textshadow" style={{ fontSize: '2rem' }}><b>Make</b></h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <br></br>
                            <SearchSide makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} view={this.state.view} />
                            <br></br>
                            <div className="d-none d-md-block">
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        <div className="card">
                                            <div className="card-body" style={{ textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                <h3><strong>Select By Make !</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    {this.state.makes.slice(0, this.state.makelength).map((make, i) =>
                                        <div key={i} className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="card">
                                                <div onClick={this.enterMakes.bind(this, make.id, make.make_name)} className="card-body" style={{ cursor: 'pointer', height: 51, padding: '0.42rem', background: '#dfe3e6' }}>
                                                    <a style={{ cursor: 'pointer' }}>
                                                        <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)} alt="Card image cap" />
                                                        {/* {this.getImage(make.assets)} */}
                                                        <span style={{ fontWeight: '700', textAlign: 'right', fontSize: 12, textTransform: 'uppercase', color: '#DA5252' }}>{make.make_name}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br>
                                        </div>
                                    )}
                                </div>

                                <br></br>
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        {this.state.viewLook === 0 ?
                                            <div className="card">
                                                <div onClick={this.changeView.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-down"></i></a>
                                                </div>
                                            </div> :
                                            <div className="card">
                                                <div onClick={this.changeHide.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-up"></i></a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-md-9">
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active carouselProduct " >
                                                <img className="d-block w-100" src={engine} alt="First slide" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="card" style={{backgroundColor: '#DA5252'}}>
                                        <div className="card-body">
                                            <h5 className="text-white card-title" style={{fontSize: '2rem' }}>{this.state.make_name}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                    Many OEM {this.state.make_name} parts have a reputation for being expensive. 
                                    We want everyone to know how affordable it can be to maintain your car with quality 
                                    used OEM {this.state.make_name} parts from BackToRoad. We offer parts for all models of {this.state.make_name}. You can 
                                    shop with confidence knowing you’re getting the same reliable, OEM quality parts here
                                    at backtoroadautoparts.com.

                                    backtoroadautoparts.com is your #1 solution for quality used 
                                    {this.state.make_name} parts online. Our OEM parts 
                                    search tools will find the parts you need in 
                                    moments and save you a trip to your local used spare 
                                    parts store or {this.state.make_name} wrecking yard. At BackToRoad Auto Parts 
                                    you can always expect to save up to 70% off new and aftermarket part 
                                    prices, expert customer service from our team of part specialists, and a 
                                    hassle-free 1 Year Warranty you can depend on.
                                    </h6>
                                </div>
                            </div>
                            <div className="row container">
                            {
                                this.state.modelval.map((model,i) =>
                                    <div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                        <div className="card modelCard" style={{border:0}}>
                                            <Link to={{pathname: `/parts`, state: { getmodelname:model.model_name,getmakename:this.state.make_name }}} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{model.model_name}</Link>
                                        </div>
                                    </div>
                                )
                            }
                                
                            </div>
                            <Parts />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Makes;