import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/saturntra.jpeg';
import gif from '../btr-gif.gif';
class SaturnTransmissionManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Saturn',
            makeid : 438,
            selectedpartid : 1,
            selectedpartname : 'Transmission',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Saturn'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Saturn Transmissions</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Saturn Transmissions in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Saturn Transmissions
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Saturn Transmissions </u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Ford} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Performance checked and quality assured Used Saturn Transmissions 
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Saturn models
                                                    </h6>
                                                    <br></br>
                                                    <h6><strong>Buy Quality Saturn Transmissions Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Smart Saturn owners, dealers and mechanics choose BackToRoad Auto Parts for all of their <strong>
                                                        used Saturn
                                                        parts</strong>. If your Saturn’s engine needs replacement or you’re due for an entirely new transmission, we’ve
                                                        got you covered. From used alternators to hubcaps to bumpers and beyond, each and every one of our
                                                        parts are inspected at least twice before they leave our warehouse. Therefore, you can enjoy peace of
                                                        mind knowing that your used Saturn parts are safe and durable.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <strong>The Saturn Corporation</strong>, also known as <strong>Saturn LLC</strong>, was an American automobile manufacturer, a
                                                        registered trademark established on January 7, 1985, as a subsidiary of General Motors.The company
                                                        marketed itself as a "different kind of car company" and operated somewhat independently from its parent
                                                        company for a time with its own assembly plant in Spring Hill, Tennessee, unique models, and a separate
                                                        retailer network and was GM's attempt to compete with Japanese imports and transplants in the US
                                                        compact car market.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you’re unsure which used Saturn parts to order, BackToRoad Auto Parts knowledgeable salespeople
                                                        can guide you. Our large selection saves you valuable time and money. That means no more searching
                                                        high and low for that special part or grappling with pricey retailers or dealerships. And in the unlikely
                                                        event that a part is not in stock, we can find it for you and have it delivered anywhere you want -- fast.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts provides major items like Used Transmissions, Used engines, Used
                                                        alternators,Used wheel,Used hoods, Used break systems and more. Every part is doubly inspected before
                                                        shipping, so you can enjoy peace of mind knowing that your used Saturn parts are durable, reliable and
                                                        safe.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default SaturnTransmissionManagement;