import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/jeeptra.jpg';
import gif from '../btr-gif.gif';
class JeepTransmissionManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Jeep',
            makeid : 193,
            selectedpartid : 1,
            selectedpartname : 'Transmission',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Jeep'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    :
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Jeep Transmissions</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Jeep Transmissions in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Jeep Transmissions
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Jeep Transmissions </u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Ford} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Performance checked and quality assured Used Jeep Transmissions 
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Jeep models
                                                    </h6>
                                                    <br></br>
                                                    <h6><strong>Buy Quality Jeep Transmissions Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Jeep to this day continues to fascinate the admirers of the automobiles. Jeep is still the first preference of an
                                                        ATV and SUV. Even as the new models are being introduced into the market, it is still recommended to go for
                                                        the used Jeep and the used Jeep parts over the new ones because of the cost. Though the Jeep is used for
                                                        military or commercial purposes, it is mostly preferred for regular domestic use.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you think that buying <strong>online Jeep parts</strong> only means getting all the parts or none at all, then we are here to
                                                        clear that misconception because from our site you can get the <strong>used OEM parts</strong> individually.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Here at BackToRoad, our future together begins here with honest, quality used Jeep parts for sale at cheap
                                                        prices to get your Jeep safely back on the road.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <strong>used Jeep Parts</strong>, such as engines, transmissions, wheels,alternators, hoods and more. Our fully stocked
                                                        facility offers parts for any make or year of Jeep, whether you need items for a <u>Cherokee</u>, <u>Wagoneer</u>,
                                                        <u>Jeepster</u>, <u>Wrangler</u>, <u>Liberty</u> or another model.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you’re unsure which used Jeep parts to order, BackToRoad Auto Parts knowledgeable salespeople can
                                                        guide you. Our large selection saves you valuable time and money. That means no more searching high
                                                        and low for that special part or grappling with pricey retailers or dealerships. And in the unlikely event
                                                        that a part is not in stock, we can find it for you and have it delivered anywhere you want – fast
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify',color:'rgb(218, 82, 82)'}}>
                                                    Get your used Jeep parts from a reliable auto parts seller today, call us at 1-800-608-3868
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default JeepTransmissionManagement;