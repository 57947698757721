import React, { Component } from 'react';
import Slider from './components/home/slide';
import Parts from './components/home/parts';
import Make from './components/home/make';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import HomeQuality from './components/home/homequality';
import Guarantee from './components/guarantee';
import './home.css';
import SearchSide from './searchSide';
class Home extends Component {
  constructor(){
    super();
    this.state = {
      openfeedback : false,
      make_name : null,
      make_id : null
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  openFeedback(){
    this.setState({
      openfeedback : true
    });
  }
  closeFeedback(){
    this.setState({
      openfeedback : false
    });
  }
  handleMake = (makeid,makename) => {
    console.log('check',makeid,makename)
    this.setState({
      make_id : makeid,
      make_name : makename
    });
  }

  render() {
    return (
        <div className="Home">
            <Header menuHome='home' />
            <div className="biggerone">
            <Slider  makename={this.state.make_name} makeid={this.state.make_id} />
            </div>
            <div className="smallerone">
            <SearchSide makename={this.state.make_name} makeid={this.state.make_id}/>
            </div>
            <HomeQuality/>
            <Parts /> 
            <Make onSelectMake={this.handleMake} />
            <br></br>
            <Guarantee/>
            <Footer onSelectMakeFoot={this.handleMake} />  
            {/* <div className="sidefloat d-none d-md-block">
              <ul className="list-group h11">
                <li className="list-group-item sideBtnDesignPhone"><i className="fa fa-phone"></i>&nbsp;&nbsp;&nbsp;&nbsp;1-800-608-3868</li>
              </ul>
              <ul className="list-group h11">
                <li className="list-group-item sideBtnDesignMail"><i className="fa fa-envelope"></i>&nbsp;&nbsp;&nbsp;&nbsp;<span className="hoverMail">Mail Us</span></li>
              </ul>
              <ul className="list-group h12">
                <li onMouseLeave={this.closeFeedback.bind(this)} onMouseOver={this.openFeedback.bind(this)} className="list-group-item sideBtnDesignSecured">
                <i class="fa fa-smile-o" style={{fontSize:22}}></i>&nbsp;&nbsp;&nbsp;&nbsp;Feedback<br></br>
                  {this.state.openfeedback === true ?
                  <div className="row">
                    <div className="col-sm-6">
                      <a>
                        <svg style={{padding:'5%',cursor:'pointer'}} aria-hidden="true" data-prefix="far" data-icon="grin" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-grin fa-w-16 fa-2x"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm105.6-151.4c-25.9 8.3-64.4 13.1-105.6 13.1s-79.6-4.8-105.6-13.1c-9.9-3.1-19.4 5.4-17.7 15.3 7.9 47.1 71.3 80 123.3 80s115.3-32.9 123.3-80c1.6-9.8-7.7-18.4-17.7-15.3zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z" class=""></path></svg>
                      </a>
                    </div>
                    <div className="col-sm-6">
                      <svg style={{padding:'5%',cursor:'pointer'}} aria-hidden="true" data-prefix="far" data-icon="smile-beam" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-smile-beam fa-w-16 fa-3x"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm84-143.4c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.6-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.2-8.4-25.3-7.1-33.8 3.1zM136.5 211c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 6-4.5 5.7-8.3-3.3-42.1-32.2-71.4-56-71.4s-52.7 29.3-56 71.4c-.3 3.7 2.1 7.2 5.7 8.3 3.4 1.1 7.4-.5 9.3-3.7l9.5-17zM328 152c-23.8 0-52.7 29.3-56 71.4-.3 3.7 2.1 7.2 5.7 8.3 3.5 1.1 7.4-.5 9.3-3.7l9.5-17c7.7-13.7 19.2-21.6 31.5-21.6s23.8 7.9 31.5 21.6l9.5 17c2.1 3.7 6.2 4.7 9.3 3.7 3.6-1.1 6-4.5 5.7-8.3-3.3-42.1-32.2-71.4-56-71.4z" class=""></path></svg>
                    </div>
                    <div className="col-sm-6">
                    <svg style={{padding:'5%',cursor:'pointer'}} aria-hidden="true" data-prefix="far" data-icon="frown-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-frown-open fa-w-16 fa-2x"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-48-248c0-17.7-14.3-32-32-32s-32 14.3-32 32 14.3 32 32 32 32-14.3 32-32zm128-32c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-80 112c-35.6 0-88.8 21.3-95.8 61.2-2 11.8 9 21.5 20.5 18.1 31.2-9.6 59.4-15.3 75.3-15.3s44.1 5.7 75.3 15.3c11.4 3.5 22.5-6.3 20.5-18.1-7-39.9-60.2-61.2-95.8-61.2z" class=""></path></svg>
                    </div>
                    <div className="col-sm-6">
                    <svg style={{padding:'5%',cursor:'pointer'}} aria-hidden="true" data-prefix="far" data-icon="angry" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-angry fa-w-16 fa-3x"><path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm0-144c-33.6 0-65.2 14.8-86.8 40.6-8.5 10.2-7.1 25.3 3.1 33.8s25.3 7.2 33.8-3c24.8-29.7 75-29.7 99.8 0 8.1 9.7 23.2 11.9 33.8 3 10.2-8.5 11.5-23.6 3.1-33.8-21.6-25.8-53.2-40.6-86.8-40.6zm-48-72c10.3 0 19.9-6.7 23-17.1 3.8-12.7-3.4-26.1-16.1-29.9l-80-24c-12.8-3.9-26.1 3.4-29.9 16.1-3.8 12.7 3.4 26.1 16.1 29.9l28.2 8.5c-3.1 4.9-5.3 10.4-5.3 16.6 0 17.7 14.3 32 32 32s32-14.4 32-32.1zm199-54.9c-3.8-12.7-17.1-19.9-29.9-16.1l-80 24c-12.7 3.8-19.9 17.2-16.1 29.9 3.1 10.4 12.7 17.1 23 17.1 0 17.7 14.3 32 32 32s32-14.3 32-32c0-6.2-2.2-11.7-5.3-16.6l28.2-8.5c12.7-3.7 19.9-17.1 16.1-29.8z" class=""></path></svg>
                    </div>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                        <h6 style={{textAlign:'center'}}>Click Your Feedbacks</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  : ''  
                }
                </li>
              </ul>
            </div>  */}
        </div>
    );
  }
}

export default Home;
