import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
class Shipping extends Component {
  render() {
    return (
        <div className="Warranty">
            <Header/>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundColor: '#DA5252',height: 100,backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',flex: 1,justifyContent: 'center',alignItems: 'center'}}>
                    <div class="container">
                        <h1 class="text-white"><b>Shipping & Handling</b></h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundImage: "url(" + bg + ")",height: 'auto',backgroundRepeat: 'repeat',backgroundSize: 394,backgroundColor: 'rgba(255,255,255,0.4)'}}>
                    <div class="col-md-8 offset-md-2 col-sm-12 col-lg-8 offset-lg-2 aboutcontent">
                        <h3 style={{textAlign: 'center',color:'#A63434'}}>We offer the following convenient delivery options within the United States: </h3>
                        <br></br>
                        <h6 style={{textAlign: 'justify' ,}}>Business days are Monday-Friday, excluding federal holidays within the United States. FedEx and certain
                        freight carrier delivery is not available on Saturday or Sunday..</h6>
                        <br></br>
                        <h6 style={{textAlign: 'justify',color:'#A63434'}}>We process your orders within 1-2 business days. Once the part is out the door, we will send you the
tracking numbers and necessary details to track the order to your email given at the time of placing the
order.</h6><br></br>
                        <h6 style={{textAlign: 'justify',color:'#A63434'}}>The MAXIMUM time frame we show on paper for delivery of Non-Freight orders (approx. less than 70
pounds) is 5-7 business days but you might also receive it sooner. For delivery of Freight orders (approx.
greater than 70 pounds), the maximum time frame shown on paper is 7-10 business days but a lot of our
customers receive it sooner as well.</h6><br></br>
                        <h6 style={{textAlign: 'justify'}}>If the purchaser needs an order to be expedited, please notify the same to an authorized representative.</h6>
<br></br>
                        <h6 style={{textAlign: 'justify'}}>At the time of delivery, it is the responsibility of the purchaser to make sure that there are no shipping
damages or such. To return an order back, a claim has to be made within 4 days of the delivery date to
an authorized representative of BackToRoad Auto Parts.</h6><br></br>
                        <h6 style={{textAlign: 'justify'}}>Orders are not shipped on Saturdays, Sundays or Public Holidays.</h6>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>We&#39;re continuously striving to get your products to you sooner, however, in some unforeseen
circumstances it may take a bit longer. In this case, we&#39;ll notify you of this delay immediately.</h6>
<br></br>
                        <h6 style={{textAlign: 'justify'}}>We are not responsible for any delays caused by natural calamities and unavoidable circumstances.</h6>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>We currently do not ship to P.O. Box addresses. These type of addresses may be used for billing.</h6>
                    </div> 
                </div>
            </div>
            <Footer />    
        </div>
    );
  }
}

export default Shipping;