import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import {Link} from 'react-router-dom';
class Success extends Component {
  render() {
    return (
      <div classNameName="Success">
        <Header/>
          <div className="container-fluid">
                <div className="row"
                    style={{backgroundImage: "url(" + bg + ")",height: 'auto',backgroundRepeat: 'repeat',backgroundSize: 394,backgroundColor: 'rgba(255,255,255,0.4)'}}>
                    <div className="col-md-8 offset-md-2 col-sm-12 col-lg-8 offset-lg-2 aboutcontent">
                    <h4 style={{textAlign:'justify',color:'#DA5252',fontWeight:'bolder'}}>Thank you for choosing BackToRoad Auto Parts, LLC. We have your part in stock ! Check your email, your quote is on its way. Let’s get you back to road.</h4>
                    <br></br>
                        <p style={{textAlign:'justify'}}>
                          <span><strong>Read Carefully</strong></span> : We have emailed you a detailed quote for the parts you have requested. Make sure
                          you provide us an actual email and phone number, so you can get your quote immediately. Our
                          Senior Auto Parts Specialist might call you in case we need any additional vehicle specification
                          details to provide you with a quote.
                        </p>
                        <br></br>
                        <h5 style={{textAlign:'justify'}} >For the fastest service and quote, call us at 1-800-608-3868 during the hours of 8.30 am PST to 4.30 pm PST -Monday to Friday</h5>
                        <br></br>
                        <h6 style={{textAlign:'right'}}><Link style={{color:'blue'}} className="" to="/" ><u>Go Back to home</u></Link></h6>
                    </div> 
                </div>
            </div>
        <Footer />

      </div>
    );
  }
}

export default Success;