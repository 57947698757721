import React ,{Component} from 'react';
import HeaderDashboard from './header/index';

class Dashboard extends Component {
    render () {
        return (
            <div className="dashboard">
                <HeaderDashboard />
            </div>
        );
    }
}
export default Dashboard;
