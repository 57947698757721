import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/Ford-tra.jpg';
import gif from '../btr-gif.gif';
class FordManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Ford',
            makeid : 117,
            selectedpartid : 1,
            selectedpartname : 'Transmission',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Ford'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Ford Transmissions</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Ford Transmissions in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Ford Transmissions                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Ford Transmissions</u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Ford} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Road Tested, Performance checked and quality assured Used Ford Transmissions
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Ford models
                                                    </h6>
                                                    <br>
                                                    </br>
                                                    <h6><strong>Buy Quality Ford Transmission Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <strong>Ford Motor Company</strong> is an American <u>multinational</u> automaker that has its main headquarter in&nbsp;
                                                        <u>Dearborn, Michigan,</u> a suburb of <u>Detroit</u>. The company sells automobiles and commercial vehicles under
                                                        the <u>Ford</u> brand and most luxury cars under the <u>Lincoln</u> brand.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Ford Motor Company sells a broad range of automobiles under the Ford marque worldwide, and an
                                                        additional range of luxury automobiles under the <u>Lincoln</u> marque in the United States.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Given the worldwide expansion of its operations, it is likely to assume that the demand to buy used ford
                                                        parts has reached a tremendous height because a brand like Ford to ensure that it keeps true to its name
                                                        needs quality used ford parts that give of the same new-purchase vibe.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        From BackToRoad Auto Parts you will get immediate assistance with guaranteed used car parts that will
                                                        make your vehicle seem like new furnished than a repaired one.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Buying with BackToRoad saves you up to 70% off new and aftermarket prices and takes the guesswork
                                                        out of buying parts! Anytime you need a part, we’ll be here, your one stop shop for genuine OEM Ford
                                                        parts online.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Finding <u>used Ford parts</u> doesn’t have to be a challenge. Whether you own, deal or repair a&nbsp; 
                                                        <u>Crown Victoria</u>
                                                        , <u>Aspire</u>, <u>Edge</u>, <u>Escort</u>, <u>Explorer</u>, <u>Taurus</u> and more. BackToRoad Auto Parts is your source for big
                                                        and small auto parts. Used Engines, Used Transmissions, Used alternators, Used wheel, Used hoods,
                                                        Used brake systems and more are available at our fully stocked warehouses. Each item we sell is doubly
                                                        inspected for safety and performance, so you can be confident that when your part arrives at your
                                                        doorstep, you’re getting top quality used Ford parts.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify',color:'rgb(218, 82, 82)'}}>
                                                    Get your used Ford parts from a reliable auto parts seller today, call us at 1-800-608-3868
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default FordManagement;
