import React, { Component } from 'react';
import  img from './logo.webp';
import {Link} from 'react-router-dom';
import './header.css';
import phone from '../photo.png';
class Header extends Component {
    constructor(){
        super();
        this.state={
            active : 10
        }
    }
    componentWillMount(){
        if(window.location.href.indexOf("About") > -1) 
            {
                 this.setState({
                    active : 1
                 })
            } else if(window.location.href.indexOf("Parts") > -1) 
            {
                 this.setState({
                    active : 2
                 })
            } else if(window.location.href.indexOf("Warranty") > -1) 
            {
                 this.setState({
                    active : 3
                 })
            } else if(window.location.href.indexOf("CustomerService") > -1) 
            {
                 this.setState({
                    active : 4
                 })
            } else if(window.location.href.indexOf("Shipping-Handling") > -1) 
            {
                 this.setState({
                    active : 5
                 })
            } else if(window.location.href.indexOf("FAQ") > -1) 
            {
                 this.setState({
                    active : 6
                 })
            } else if(this.props.menuHome === 'home') {
                this.setState({
                    active : 10
                 });
            } else {
                this.setState({
                    active : 19
                 });
            }
    }
  render() {
    return (
      <div className="Header">
        <nav className="navbar navbar-light bg-light borderDown bg-white">
            <div className="col-lg-5 offset-lg-1">
                <h1 className="navbar-brand ">
                    <Link to="/">
                        <img src={img} alt="BackToRoad logo"/>
                    </Link>
                </h1>
            </div>
            <div className="col-lg-3 offset-lg-3" style={{textAlign:'center'}}>
                <a href="tel:18006083868" style={{fontSize:'2rem', color:'black'}}>
                    <img src={phone} alt="BackToRoad logo"/>&nbsp;
                        <strong>1-800-608-3868</strong>
                </a>
                <h3 style={{textAlign:'center',fontSize:'1rem'}}>
                To place an Order : Call our Auto Parts Team now or Find your part below
                </h3> 
            </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light" style={{height:65}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav container" style={{width:'100%',height:65,marginTop:'-2px'}}>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===10 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/" >Home</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===1 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/About">About</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===2 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/Parts">Parts</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===3 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/Warranty">Warranty</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===4 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/CustomerService">Customer Service</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===5 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/Shipping-Handling">Shipping & Handling</Link>
                    </li>
                    <li className={"headerSpaceMenu nav-item " +(this.state.active===6 ? 'active' : '')}>
                        <Link className="nav-link text-black menuFont" to="/FAQ">FAQ</Link>
                    </li>
                    
                </ul>
            </div>
        </nav>
      </div>
    );
  }
}
export default Header;