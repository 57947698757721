import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import about from './about.jpg';
import './about.css';
import Guarantee from './components/guarantee';
class About extends Component {
    constructor(){
        super();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
  render() {
    return (
        <div className="About">
            <Header/>
            {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src={about} style={{height:270}} alt="about"/>
                    </div>
                </div>
            </div> */}
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundColor: '#DA5252',height: 100,backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',flex: 1,justifyContent: 'center',alignItems: 'center'}}>
                    <div class="container">
                        <h1 class="text-white"><b>About</b></h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid bgbtr">
                <div class="row"
                    style={{backgroundImage: "url(" + bg + ")",height: 'auto',backgroundRepeat: 'repeat',backgroundSize: 394,backgroundColor: 'rgba(255,255,255,0.8)'}}>
                    <div class="container">
                        <br></br>
                        <h1 ><b>BackToRoad Auto Parts</b></h1>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>Did you just find out that the engine of your car or truck seized up or is overheated? Did the gears of your transmission just start slipping? Your drive shaft just got bent or is worn out? Your ECM is showing error codes? Your ABS is malfunctioning? Your axle assembly just fell apart?</h6>
                        <br></br>
                        <div class="row">
                            <div class="col-sm-12 col-md-5 col-lg-5">
                                <h1 style={{fontSize: '3.45rem'}}><b>THE <span style={{color:'#DA5252'}}>W</span>IDEST RANGE OF HIGH QUALITY AUTO PARTS FOR ANY VEHICLE</b></h1>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12">
                                <h4>Why BackToRoad Auto ?</h4>
                                <br></br>
                                <h6 style={{textAlign: 'justify'}}>Are you having a hard time getting back to road? Do not worry, put the burden onto us and let us provide you with the auto parts you are looking for.</h6>
                                <h6 style={{textAlign: 'justify'}}>We deliver quality used parts to all 50 states and 5 self-governing territories of USA and all over Canada. We work on a live inventory with auto parts updated instantly according to availability, so we can ensure a fast shipment. With our tie-up shipping carriers all over the country, even if the parts you are looking for is not available nearby you or in your state, we ensure quick delivery to your residence or to your mechanic. Every Auto Part that we provide are used Original Equipment Manufacturer (OEM) parts which are quality checked to be in A grade condition. Our Senior Parts Specialists with over 20 years of combined experience in the industry, work round the clock to help you with finding the exact auto part you are looking for by matching the specifications of your vehicle and by decoding your VIN number.</h6>
                                <h6 style={{textAlign: 'justify'}}>We help customers with all major vehicle manufacturer parts and also go back as far as 1960!</h6>
                            </div>
                        </div>
                        <br></br>
                        <div class="row">
                            <h2 style={{textAlign: 'center'}}>Speak now to one of our Senior Auto Parts Specialist at 
                            <span style={{color: '#DA5252'}}><b> 1-800-608-3868</b></span> and be one among of our satisfied customers </h2>
                        </div>
                    </div>   
                </div>
            </div>
            <br></br>
            <Guarantee/>
            <Footer />    
        </div>
    );
  }
}

export default About;
