import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import Guarantee from './components/guarantee';
import './about.css';
import SearchSide from './searchSide';
import cadillac from './bc.png';
import axios from 'axios';
import { IP } from './constants';
import './latestproducts.scss';
import img from '../components/logo.png';
import engine from '../images/engines-01.png';
import axles from '../images/Axelsbg-01.png';
import steering from '../images/stearing bg-01.png';
import transmission from '../images/transmissionsbg-01.png';
import abs from '../images/absbg-01.png';
import transfer from '../images/transfercase bg-01.png';
import struts from '../images/struts-01.png';
import alternator from '../images/alternater bg-01.png';
class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: null,
            selectedpartid: null,
            selectedpartname: '',
            view: 0,
            makes: [],
            makelength: 20,
            viewLook: 0,
            makeid: null,
            makename: null,
            viewType: 1,
            returnData: [],
            imageCover: '',
            textCover: null
        }
    }
    componentDidMount() {
        const { status } = this.props.location.state;
        localStorage.setItem('status', status);
        this.setState({
            location: status
        });
        window.scrollTo(0, 0);
        this.getMakeSpecific();
        this.getProducts(status);
        if (status === "Engines") {
            this.setState({
                imageCover: engine,
                textCover: ''
            });
        } else if (status === "Steering Column") {
            this.setState({
                imageCover: steering,
                textCover: '',
                location : 'Steering Columns'
            });
        } else if (status === "Transmission") {
            this.setState({
                imageCover: transmission,
                textCover: '',
                location : 'Transmissions'
            });
        } else if (status === "A.B.S") {
            this.setState({
                imageCover: abs,
                textCover: ''
            });
        } else if (status === "Axle Assemblies") {
            this.setState({
                imageCover: axles,
                textCover: ''
            });
        } else if (status === "Alternator") {
            this.setState({
                imageCover: alternator,
                textCover: '',
                location : 'Alternators'
            });
        } else if (status === "Transfer Case") {
            this.setState({
                imageCover: transfer,
                textCover: ''
            });
        } else if (status === "Struts") {
            this.setState({
                imageCover: struts,
                textCover: ''
            });
        }

    }
    getProductsSold(id, partname) {
        this.setState({
            selectedpartid: id,
            selectedpartname: partname
        });
        window.scrollTo(0, 0);
    }
    viewMore() {
        this.setState({
            view: 1
        });
        window.scrollTo(0, 0);
    }
    getMakeSpecific(e) {
        axios.post(IP + '/getMakeSpecific')
            .then((response) => {
                console.log('nioss', response)
                if (response.status === 200) {
                    this.setState({
                        makes: response.data.rows
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    enterMakes(id, name) {
        this.setState({
            makeid: id,
            makename: name
        });
        window.scrollTo(0, 0);
    }

    changeView() {
        this.setState({
            makelength: 50,
            viewLook: 1
        });
    }
    changeHide() {
        this.setState({
            makelength: 10,
            viewLook: 0
        });
    }
    getProducts(location) {

        axios.post(IP + '/getSpecificLatestProducts/' + location)
            .then((response) => {
                console.log('sdsd', response)
                this.setState({
                    returnData: response.data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="Products">
                <Header />
                <div className="container-fluid">
                    <div className="row"
                        style={{
                            backgroundColor: '#DA5252', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center',
                            backgroundImage: "url(" + bg + ")", backgroundRepeat: 'repeat',
                            backgroundSize: 145
                        }}>
                        <div className="container">
                            <h1 className="text-white textshadow" style={{ fontSize: '2rem' }}><b>Used {this.state.location}</b></h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-3 col-md-3">
                            <br></br>
                            <SearchSide makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} view={this.state.view} partid={this.state.selectedpartid} partname={this.state.selectedpartname} />
                            <br></br>
                            <div className="d-none d-md-block">
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        <div className="card">
                                            <div className="card-body" style={{ textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                <h3><strong>Select By Make !</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    {this.state.makes.slice(0, this.state.makelength).map((make, i) =>
                                        <div key={i} className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="card">
                                                <div onClick={this.enterMakes.bind(this, make.id, make.make_name)} className="card-body" style={{ cursor: 'pointer', height: 51, padding: '0.42rem', background: '#dfe3e6' }}>
                                                    <a style={{ cursor: 'pointer' }}>
                                                        <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)} alt="Card image cap" />
                                                        {/* {this.getImage(make.assets)} */}
                                                        <span style={{ fontWeight: '700', textAlign: 'right', fontSize: 12, textTransform: 'uppercase', color: '#DA5252' }}>{make.make_name}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br>
                                        </div>
                                    )}
                                </div>

                                <br></br>
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        {this.state.viewLook === 0 ?
                                            <div className="card">
                                                <div onClick={this.changeView.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-down"></i></a>
                                                </div>
                                            </div> :
                                            <div className="card">
                                                <div onClick={this.changeHide.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-up"></i></a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-md-9">
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active carouselProduct " >
                                                <img className="d-block w-100" src={this.state.imageCover} alt="First slide" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    {
                                        this.state.location === 'Engines' ?
                                            <div>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                buying a quality used OEM Engine Assembly. We offer parts for all makes and models
                                                including a wide variety of vehicle types and styles. If you are looking for an Engine
                                                Assembly, let us know today. Engines will be inspected and are tested to be in good working
                                                condition. Engines will be inspected for water damage.
                                    </h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>What are common reasons for replacing my vehicle’s Engine Assembly?</strong></h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>Common reasons for replacing an engine are accidents, water damage, and burning
                                                    coolant/oil. When you are ready to replace or restore the function of your Engine Assembly,
                                                    BackToRoad Auto is your source for quality used OEM auto parts.
                                    </h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Where should I go online to buy a used OEM Engine Assembly that comes with a
                                                warranty? Why should I care about a warranty?
                                    </strong></h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                    BackToRoad Auto! Easily find the used Engine Assembly you need and verify compatibility
                                                    with your vehicle using our advanced online part search tools, no mechanical experience
                                                    required! If you need help, our experienced part specialists are standing by with access to
                                                    part diagrams, parts catalogs, chassis codes, VIN decoders, and more to help you find the
                                                    OEM Engine Assembly you need. At BackToRoad Auto, we stand behind every used Engine
                                                    Assembly we offer to our valued customers. We include with all of our parts a hassle-free, 30
                                                    day standard Warranty at no extra charge. We strive to bring you a reliable alternative to
                                                    buying a junkyard or salvage yard Engine Assembly. Buying quality used parts from
                                                    BackToRoad also brings you incredible savings of up to 70% off new and aftermarket prices.
                                    </h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Is a used OEM Engine Assembly better than new or aftermarket one? What does OEM
                                    mean?</strong></h6>
                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                    OEM is an abbreviation for Original Equipment Manufacturer which means the parts are
                                                    made directly by your vehicle’s make. Because these parts are made to fit the specifications
                                                    of your make and model, you can expect an exact fit. However, new OEM parts are not
            
                                                    cheap. But when you buy used from BackToRoad Auto, you can expect significant savings off
                                                    the MSRP. Because aftermarket parts are manufactured by a third-party company, they may
                                                    not offer an exact fit since these parts are produced at a high volume and made to similar
                                                    specifications. This makes a used OEM Engine Assembly the safer and most affordable
                                                    option!
                                    </h6>
                                            </div> :
                                            this.state.location === "Steering Column" ?
                                                <div>
                                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                    buying a quality used Steering Column. We offer parts for all makes and models
                                                    including a wide variety of vehicle types and styles. If you are looking for an Steering Column, let us know today. Engines will be inspected and are tested to be in good working
                                                    condition. Steering Column will be inspected for water damage.
                                    </h6>
                                                    <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Where should I go online to buy a used Steering Column that comes with a
                                                    warranty? Why should I care about a warranty?
                                    </strong></h6>
                                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                        BackToRoad Auto! Easily find the used Steering Column you need and verify compatibility
                                                        with your vehicle using our advanced online part search tools, no mechanical experience
                                                        required! If you need help, our experienced part specialists are standing by with access to
                                                        part diagrams, parts catalogs, chassis codes, VIN decoders, and more to help you find the
                                                        Steering Column you need. At BackToRoad Auto, we stand behind every used Steering Column we offer to our valued customers. We include with all of our parts a hassle-free, 30
                                                        day standard Warranty at no extra charge. We strive to bring you a reliable alternative to
                                                        buying a junkyard or salvage yard Steering Column. Buying quality used parts from
                                                        BackToRoad also brings you incredible savings of up to 70% off new and aftermarket prices.
                                    </h6>
                                                </div> :
                                                this.state.location === "Transmission" ?
                                                    <div>
                                                        <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                        buying a quality used Transmission. We offer parts for all makes and models
                                                        including a wide variety of vehicle types and styles. If you are looking for an Transmission, let us know today. Engines will be inspected and are tested to be in good working
                                                        condition. Transmission will be inspected for water damage.
                                    </h6>
                                                        <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}>
                                                            <strong>What are common reasons for replacing my car’s Transmission?</strong>
                                                        </h6>
                                                        <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                            Some problems you will face if your transmission is bad are a burning smell, unusual sounds
                                                            (like grinding noises), and leaking transmission fluid. When you are ready to replace or
                                                            restore the function of your Transmission, BackToRoad is your source for quality used OEM auto
                                                            parts.
                                    </h6>
                                                    </div> :
                                                    this.state.location === "A.B.S" ?
                                                        <div><h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                            buying a quality used ABS. We offer parts for all makes and models
                                                            including a wide variety of vehicle types and styles. If you are looking for an ABS, let us know today. Engines will be inspected and are tested to be in good working
                                                            condition. ABS will be inspected for water damage.
                                    </h6>
                                                            <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}>
                                                                <strong>What are common issues that will require me to replace my vehicle’s ABS Pump?</strong>
                                                            </h6>
                                                            <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                ABS Pump failing is recognizable for most people, as skidding and hydroplaning are likely to
                                                                occur as soon as the ABS pump needs to be replaced. Skidding and hydroplaning are very
                                                                dangerous situations, as either one can cause an accident. When you are ready to replace or
                                                                restore the function of your ABS Pump, BackToRoad is your source for quality used OEM auto
                                                                parts!
                                </h6></div> :
                                                        this.state.location === "Axle Assemblies" ?
                                                            <div>
                                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                                buying a quality used Axle Assemblies. We offer parts for all makes and models
                                                                including a wide variety of vehicle types and styles. If you are looking for an Axle Assemblies, let us know today. Engines will be inspected and are tested to be in good working
                                                                condition. Axle Assemblies will be inspected for water damage.
                                     </h6>
                                                                <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}>
                                                                    <strong>What are common issues that will require me to replace my vehicle’s ABS Pump?</strong>
                                                                </h6>
                                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                    ABS Pump failing is recognizable for most people, as skidding and hydroplaning are likely to
                                                                    occur as soon as the ABS pump needs to be replaced. Skidding and hydroplaning are very
                                                                    dangerous situations, as either one can cause an accident. When you are ready to replace or
                                                                    restore the function of your ABS Pump, BackToRoad is your source for quality used OEM auto
                                                                    parts!
                                 </h6>
                                                                <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}>
                                                                    <strong>What are common issues that will require me to replace my vehicle’s Axle Assembly or
                                 Differential?</strong>
                                                                </h6>
                                                                <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                    Bad vehicle vibrations after a collision with a bad pothole, unexpected curb, or other objects
                                                                    may indicate axle damage. Axle shafts wearing out or breakage of axle shafts can cause your
                                                                    axle assembly to fail. Ring gears and pinions inside the differential can also get damaged.
                                                                    When your axle assembly is having problems, you will notice a definite vibration in the
                                                                    steering wheel. While you may be able to continue to drive with your bad axle, you will
                                                                    begin to have serious issues and eventual failure. If the axle fails while you&#39;re on the road,
                                                                    then you could get into a serious accident. When you are ready to replace or restore the
                                                                    function of your Differential or Axle Assembly, BackToRoad is your source for quality used
                                                                    OEM auto parts!
                                </h6>
                                                            </div> :
                                                            this.state.location === "Alternator" ?
                                                                <div>
                                                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                                    buying a quality used Alternator. We offer parts for all makes and models
                                                                    including a wide variety of vehicle types and styles. If you are looking for an Alternator, let us know today. Engines will be inspected and are tested to be in good working
                                                                    condition. Alternator will be inspected for water damage.
                                     </h6>
                                                                    <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Where should I go online to buy a used Alternator that comes with a
                                                                       warranty? Why should I care about a warranty?
                                    </strong></h6>
                                                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                        BackToRoad Auto! Easily find the used Alternator you need and verify compatibility
                                                                        with your vehicle using our advanced online part search tools, no mechanical experience
                                                                        required! If you need help, our experienced part specialists are standing by with access to
                                                                        part diagrams, parts catalogs, chassis codes, VIN decoders, and more to help you find the
                                                                        Alternator you need. At BackToRoad Auto, we stand behind every used Alternator we offer to our valued customers. We include with all of our parts a hassle-free, 30
                                                                        day standard Warranty at no extra charge. We strive to bring you a reliable alternative to
                                                                        buying a junkyard or salvage yard Alternator. Buying quality used parts from
                                                                        BackToRoad also brings you incredible savings of up to 70% off new and aftermarket prices.
                                    </h6>
                                                                </div> :
                                                                this.state.location === "Transfer Case" ?
                                                                    <div>
                                                                        <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                                        buying a quality used Transfer Case. We offer parts for all makes and models
                                                                        including a wide variety of vehicle types and styles. If you are looking for an Transfer Case, let us know today. Engines will be inspected and are tested to be in good working
                                                                        condition. Transfer Case will be inspected for water damage.
                                     </h6>
                                                                        <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Where should I go online to buy a used Transfer Case that comes with a
                                                                           warranty? Why should I care about a warranty?
                                    </strong></h6>
                                                                        <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                            BackToRoad Auto! Easily find the used Transfer Case you need and verify compatibility
                                                                            with your vehicle using our advanced online part search tools, no mechanical experience
                                                                            required! If you need help, our experienced part specialists are standing by with access to
                                                                            part diagrams, parts catalogs, chassis codes, VIN decoders, and more to help you find the
                                                                            Transfer Case you need. At BackToRoad Auto, we stand behind every used Transfer Case we offer to our valued customers. We include with all of our parts a hassle-free, 30
                                                                            day standard Warranty at no extra charge. We strive to bring you a reliable alternative to
                                                                            buying a junkyard or salvage yard Transfer Case. Buying quality used parts from
                                                                            BackToRoad also brings you incredible savings of up to 70% off new and aftermarket prices.
                                    </h6>
                                                                    </div> :
                                                                    this.state.location === "Struts" ?
                                                                        <div>
                                                                            <h6 style={{ fontSize: 14, textAlign: 'justify' }}>We want people to know that we’re an affordable and dependable option when it comes to
                                                                            buying a quality used Struts. We offer parts for all makes and models
                                                                            including a wide variety of vehicle types and styles. If you are looking for an Struts, let us know today. Engines will be inspected and are tested to be in good working
                                                                            condition. Struts will be inspected for water damage.
                                     </h6>
                                                                            <h6 style={{ fontSize: 14, textAlign: 'justify', marginBottom: '1%', marginTop: '1%' }}><strong>Where should I go online to buy a used Struts that comes with a
                                                                               warranty? Why should I care about a warranty?
                                    </strong></h6>
                                                                            <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                                                                BackToRoad Auto! Easily find the used Struts you need and verify compatibility
                                                                                with your vehicle using our advanced online part search tools, no mechanical experience
                                                                                required! If you need help, our experienced part specialists are standing by with access to
                                                                                part diagrams, parts catalogs, chassis codes, VIN decoders, and more to help you find the
                                                                                Struts you need. At BackToRoad Auto, we stand behind every used Struts we offer to our valued customers. We include with all of our parts a hassle-free, 30
                                                                                day standard Warranty at no extra charge. We strive to bring you a reliable alternative to
                                                                                buying a junkyard or salvage yard Struts. Buying quality used parts from
                                                                                BackToRoad also brings you incredible savings of up to 70% off new and aftermarket prices.
                                    </h6>
                                                                        </div> : ''
                                    }







                                </div>
                            </div>
                            <hr></hr>
                            <br></br>
                            {this.state.returnData.map((sub, i) =>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="container-fluid">
                                            <div class="blog-card">
                                                <div class="meta">
                                                    <div class="photo" style={{ backgroundImage: "url(" + '/uploads/' + sub.image + ")" }}></div>
                                                    <ul class="details">
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>
                                                            <ul>
                                                                <li><p>{sub.morespecification}</p></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="description" style={{ background: "linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url(" + bg + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>

                                                    <h2>{sub.partid}</h2>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p><strong>Year</strong> : {sub.year}</p>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p><strong>Make</strong> : {sub.make}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p><strong>Model</strong> : {sub.model} </p>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p><strong>Specification</strong> : {sub.specification}</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p>Status : <span style={{ color: 'green' }}>Available</span> </p>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                                            <p class="read-more"><a style={{ cursor: 'pointer' }} onClick={this.getProductsSold.bind(this, 302, sub.partType)}><strong>GET QUOTE NOW</strong></a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="container-fluid d-md-none d-lg-none">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 col-md-12">
                            <div className="container-fluid d-md-none d-lg-none">
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        <div className="card">
                                            <div className="card-body" style={{ textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                <h3><strong>Select By Make !</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    {this.state.makes.slice(0, this.state.makelength).map((make, i) =>
                                        <div key={i} className="col-lg-6 col-md-6 col-sm-6 forSmallMake">
                                            <div className="card">
                                                <div onClick={this.enterMakes.bind(this, make.id, make.make_name)} className="card-body" style={{ cursor: 'pointer', height: 51, padding: '0.42rem', background: '#dfe3e6' }}>
                                                    <a style={{ cursor: 'pointer' }}>
                                                        <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)} alt="Card image cap" />
                                                        {/* {this.getImage(make.assets)} */}
                                                        <span style={{ fontWeight: '700', textAlign: 'right', fontSize: 12, textTransform: 'uppercase', color: '#DA5252' }}>{make.make_name}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br>
                                        </div>
                                    )}
                                </div>

                                <br></br>
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        {this.state.viewLook === 0 ?
                                            <div className="card">
                                                <div onClick={this.changeView.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-down"></i></a>
                                                </div>
                                            </div> :
                                            <div className="card">
                                                <div onClick={this.changeHide.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-up"></i></a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Guarantee />
                <Footer />
            </div>
        );
    }
}

export default Products;