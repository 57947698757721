import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/cadillacengine.jpg';
import gif from '../btr-gif.gif';
class CadillacEnginesManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Cadillac',
            makeid : 112,
            selectedpartid : 1,
            selectedpartname : 'Engine',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Cadillac'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                    <div>
                        <Header/>
                        <div class="container-fluid">
                            <div class="row"
                                style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                    }}>
                                <div class="container">
                                    <h1 class="text-white"><b>Used Cadillac Engines</b></h1>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Cadillac Engines  in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Cadillac Engines
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-3 col-sm-12 col-md-3">
                                    <br></br>
                                    <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                    <br></br>
                                </div>
                                <div className="col-lg-9 col-sm-9 col-md-9">
                                    <br></br>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <h4><strong><u>Quality Used Cadillac Engines</u></strong></h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                <img src={Ford} className="makeImage" />
                                            </div>
                                            <div className="col-sm-6 col-md-6 col-lg-6" >
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Performance checked and quality assured Used Cadillac Engines
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Cadillac models
                                                </h6>
                                                <br></br>
                                                <h6><strong>Buy Quality Cadillac Engine Online @ Best Price</strong></h6>
                                                <br></br>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    The origin from where Cadillac emerged
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <h6 style={{textAlign: 'justify'}}>
                                                    Cadillac that was formerly known as the Cadillac Motor Car Division is actually a separate part of the
                                                    America based General Motors or GM that advertises the luxury vehicles across the globe. Its initial
                                                    markets are America, China, and Canada; however, the Cadillac-branded automobiles are spread in over
                                                    34 additional markets all over the world. The Cadillac automobiles have always maintained a strong hold
                                                    in the luxury field within the USA.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    It is among the primogenital automobiles company in the world, and second in the USA only to the
                                                    companion GM marque Buick. The firm was discovered in 1902 from the remnants of the Henry Ford
                                                    Company.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    Quickly find the <strong>used Cadillac parts</strong> as per your needs and requirements
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    We know that finding the best quality <strong>used Cadillac parts</strong> can be quite a daunting task in today’s time,
                                                    so allow us to help you cross that bridge and get the hard-to-find <strong>auto parts</strong> that you need to keep your
                                                    Cadillac functioning as good as new.
                                                </h6>
                                                <h6 style={{textAlign: 'justify'}}>
                                                    If you’re unsure which used Cadillac parts to order, BackToRoad Auto Parts knowledgeable salespeople can
                                                    guide you. Our large selection saves you valuable time and money. That means no more searching high
                                                    and low for that special part or grappling with pricey retailers or dealerships. And in the unlikely event
                                                    that a part is not in stock, we can find it for you and have it delivered anywhere you want – fast
                                                </h6>
                                                <h6 style={{textAlign: 'justify',color:'rgb(218, 82, 82)'}}>
                                                Get your used Cadillac parts from a reliable auto parts seller today, call us at 1-800-608-3868
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br></br>
                        <Guarantee/>
                        <Footer />
                    </div>
                    )
                }
            </div>
        );
    }
}

export default CadillacEnginesManagement;