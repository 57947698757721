import React, { Component } from 'react';
import Home from './scenes/home';
import About from './scenes/about';
import Warranty from './scenes/warranty';
import FAQ from './scenes/faq';
import CustomerService from './scenes/cs';
import SendMail from './scenes/sendmail';
import AllParts from './scenes/parts';
import AllSearch from './scenes/searchSide';
import Products from './scenes/latestproducts';
import ShippingHandling from './scenes/shipping';
import Success from './scenes/success';
import WTD from './scenes/007/login';
import Dashboard from './scenes/007/dashboard/index';
import RecentPartManagement from './scenes/007/recentparts/recentpartmanagement';
import MakeManagement from './scenes/007/make/makemanagement';
import Makes from './scenes/makes';
import FordManagement from './scenes/makes/ford';
import DodgeManagement from './scenes/makes/dodge';
import ToyotaManagement from './scenes/makes/toyota';
import FordAbsManagement from './scenes/makes/fordabs';
import FordSteerinManagement from './scenes/makes/fordsteer';
import DodgeSpindleManagement from './scenes/makes/dodgespindle';
import FordAxleManagement from './scenes/makes/fordaxle';
import BmwAbsManagement from './scenes/makes/bmwabs';
import JeepTransmissionManagement from './scenes/makes/jeeptransmission';
import NissanTransmissionManagement from './scenes/makes/nissantransmission';
import CadillacAbsManagement from './scenes/makes/cadillacabs';
import SaturnTransmissionManagement from './scenes/makes/saturntransmission';
import CadillacEnginesManagement from './scenes/makes/cadillacengines';
import DodgeTransmissionManagement from './scenes/makes/dodgetransmission';
import {
  BrowserRouter as Router,
  Route,

} from 'react-router-dom';
import Parts from './scenes/components/home/parts';
import './app.css';
import axios from 'axios';
import {IP} from './scenes/constants';
class App extends Component {
  constructor(){
    super();
    this.state={
      makes : []
    };
  }

  componentDidMount(){
    this.getMakeSpecific();
  }

  getMakeSpecific() {
    axios.post(IP+'/getMakeSpecific')
      .then( (response) => {
        console.log('nioss',response)
        if (response.status === 200) {
          this.setState({
              makes : response.data.rows
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Router>
      <div className="App">
        {/* <Header/> */}

        {/* <Header/>
        <Menu /> */}

        <Route exact path="/" component={Home} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Warranty" component={Warranty} />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/CustomerService" component={CustomerService} />
        <Route exact path="/GetQuoteNow" component={SendMail} />
        <Route exact path="/Parts" component={AllParts} />
        <Route exact path="/AllSearch" component={AllSearch} />
        <Route exact path="/Used-Engines-Assembly" component={Products} />
        <Route exact path="/Used-Steering-Assembly" component={Products} />
        <Route exact path="/Used-Transmission-Assembly" component={Products} />
        <Route exact path="/Used-ABS-Assembly" component={Products} />
        <Route exact path="/Used-Axles-Assembly" component={Products} />
        <Route exact path="/Used-Alternator-Assembly" component={Products} />
        <Route exact path="/Used-Transfer-Case-Assembly" component={Products} />
        <Route exact path="/Used-Struts-Assembly" component={Products} />
        <Route exact path="/Shipping-Handling" component={ShippingHandling} />
        <Route exact path="/EntryToGet" component={Success} />
        <Route exact path="/WayToDark" component={WTD} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/RecentPartManagement" component={RecentPartManagement}/>
        <Route exact path="/MakeManagement" component={MakeManagement}/>
        <Route exact path="/Ford/Transmissions" component={FordManagement}/>
        <Route exact path="/Dodge/Engines" component={DodgeManagement} />
        <Route exact path="/Toyota/Engines" component={ToyotaManagement} />
        <Route exact path="/Ford/ABS" component={FordAbsManagement}/>
        <Route exact path="/Ford/Steering" component={FordSteerinManagement}/>
        <Route exact path="/Dodge/Spindle" component={DodgeSpindleManagement}/>
        <Route exact path="/Ford/Axle" component={FordAxleManagement}/>
        <Route exact path="/BMW/ABS" component={BmwAbsManagement} />
        <Route exact path="/Jeep/Transmissions" component={JeepTransmissionManagement} />
        <Route exact path="/Nissan/Transmissions" component={NissanTransmissionManagement} />
        <Route exact path="/Cadillac/ABS" component={CadillacAbsManagement} />
        <Route exact path="/Saturn/Transmissions" component={SaturnTransmissionManagement} />
        <Route exact path="/Cadillac/Engines" component={CadillacEnginesManagement} />
        <Route exact path="/Dodge/Transmissions" component={DodgeTransmissionManagement} />
        {/* <Footers /> */}
        {this.state.makes.map((make,i) =>
          <Route exact path={`/make/${make.make_name}`} component={Makes}/>
        )
        }
        
        
      </div>
      </Router>
    );
  }
}

export default App;