import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import Guarantee from './components/guarantee';
class Warranty extends Component {
    constructor(){
        super();
        window.scrollTo(0, 0);
    }
  render() {
    return (
        <div className="Warranty">
            <Header/>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundColor: '#DA5252',height: 100,backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',flex: 1,justifyContent: 'center',alignItems: 'center'}}>
                    <div class="container">
                        <h1 class="text-white"><b>Warranty</b></h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundImage: "url(" + bg + ")",height: 'auto',backgroundRepeat: 'repeat',backgroundSize: 394,backgroundColor: 'rgba(255,255,255,0.4)'}}>
                    <div class="col-md-8 offset-md-2 col-sm-12 col-lg-8 offset-lg-2 aboutcontent">
                        <h3 style={{textAlign: 'center',color:'black',fontWeight:'700'}}>To get your vehicle Back To Road is our number one priority. </h3>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>We provide a standard 30 day warranty. We warranty the automotive part we provide to be free from defects in material for the warranty period. It begins from the date purchased. If the part we deliver is incorrect, defective or damaged we will provide you with a replacement. If a replacement is not available, we provide a refund.</h6>
                        <h6 style={{textAlign: 'justify'}}>This warranty does not cover any expenses incurred in the removal and / or reinstallation of the auto part. Specifically, this warranty does not cover any expenses for time, rental or other parts not purchased from us, or any labour. This warranty covers replacing the part only. This warranty is offered only to the original purchaser of the part. This warranty does not apply, and will be voided, if the auto part sold has been damaged by abuse, misuse, accident, neglect, or alteration, or if it has been repaired by anyone. Your warranty will be null and void if the part fails due to improper installation.</h6>
                        <h6 style={{textAlign: 'justify'}}>BackToRoad Auto Parts will not be held liable for parts delivered to the address provided by the purchaser at the time of purchase but not taken into possession by the customer.</h6><br></br>
                        <br></br>
                        <h4><u>RETURN POLICY:</u></h4>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>If the auto part sold should prove defective in material within the warranty period, warranty claims should be presented to an authorized personnel of BackToRoad Auto Parts. Do not return the part back before receiving a Return Merchandise Authorization (RMA) from us through Email and the original purchaser must return the auto part to the specified address in the RMA. Returning of the part is the responsibility of the purchaser. We will, at our option, replace or refund the original sale amount.</h6>
                        <h6 style={{textAlign: 'justify'}}>
                            <span>*</span>
                            If the part is operated without proper lubrication or cooling regardless of the reason.
                        </h6>
                        <h6 style={{textAlign: 'justify'}}>
                            <span>*</span>
                            If the part is used for racing or testing purposes.
                        </h6>
                        <h6 style={{textAlign: 'justify'}}>
                            <span>*</span>
                            If the part is improperly installed.
                        </h6>
                        <br></br>
                        <h4><u>TERMS AND CONDITIONS OF WARRANTY:</u></h4>
                        <br></br>
                        <h6 style={{textAlign: 'justify'}}>Engines Warranties are limited to manufacturing defects in the engine block, cylinders heads, pistons, crankshafts, camshafts, valves. Engine assemblies are understood to consist of a long block and engine warranties are strictly limited to the long block itself. Any other parts left on the engine assembly at the time of sale are included for possible purchaser convenience and are to be used at the purchaser’s option only. These parts are not covered under the warranty. We do not warranty on any attached accessories or add on parts such as but not limited to hoses, wire harnesses, sensors, cables, manifolds, turbos, flywheel, water pumps, valve covers etc. BackToRoad Auto Parts is not at all liable to these add on parts listed as they are high wear and tear parts. New fluids, filters, seals and gaskets must be used in installation of part. Engine oil must be of the proper viscosity for the season of the year and transmission fluid must be the type recommended by the manufacturer of the vehicle. All fluids must conform to manufactures for the vehicle. Mitsubishi engines must have new timing belt tensioners installed or warranty is void.</h6>
                        <h6 style={{textAlign: 'justify'}}>Carburettor is sold as is. However they are guaranteed to be rebuildable.</h6>
                        <h6 style={{textAlign: 'justify'}}>A cylinder head itself is checked not to be cracked or deformed and is guaranteed to be in a good condition. Valves, Cams, valve springs, Retainers, locks and seals are not included in the warranty and might need to be remachined- if required. This warranty does not apply to any of the following: Damage caused by improper installation, overheating, abuse or neglect.</h6>
                        <h6 style={{textAlign: 'justify'}}>Speedometer clusters/ Instrument clusters are guaranteed to be checked and to be in a good working condition. We do not associate this warranty with any mileage claims made by the purchaser. There is no guarantee or warranty on the mileage. Speedometer Clusters bought by the purchaser for specific manufacturer models such as but not limited to Cadillac, Audi, Mercedes and BMW may need to reprogrammed by an Authorized Dealer. BackToRoad Auto Parts do not accept any return or refund claim unless the purchaser can provide a written documentation from an Authorized Dealer stating that the part was reprogrammed and is still not functioning.</h6>
                        <h6 style={{textAlign: 'justify'}}>Struts are inspected and are guaranteed to be in a good working condition. We warranty on the spring, shock absorbers and the strut tower. Actual Ride quality is not an aspect covered under the warranty.</h6>
                        <h6 style={{textAlign: 'justify'}}>Lower Control Arms and Upper Control Arms are guaranteed to be true to its shape and an exact fit for the purchaser’s vehicle. There is no warranty or guarantee associated with bolts, bushings, ball joints, bearings or rubber seals.</h6>
                        <h6 style={{textAlign: 'justify'}}>Transmission warranties are limited to manufacturing defects and do not apply to repairs or replacement as a result of any accident or misuse. We do not warranty repair or replacement of any related transmission items, including specifically and without limitation, to all components of the cooling, fuel, electrical, transmission control system, and all ignition system components, belts, hoses and filters.</h6>
                        <h6 style={{textAlign: 'justify'}}>Any engine and transmission returned not in the same assembled condition as it was received will not be refunded under any circumstances. If the engine or transmission is disassembled in any way without our express written authorization it will void the warranty.</h6>
                        <h6 style={{textAlign: 'justify'}}>OEM auto parts are interchangeable with multiple years, makes and models which means the same part is an exact fit for multiple makes and models as determined by OEM standards. We guarantee the part we sell to fit your vehicle.</h6>
                        <h6 style={{textAlign: 'justify'}}>Although BackToRoad Auto Parts makes every effort to make the part look as nice as possible before it ships no warranty or guarantee is made towards the appearance of that part.</h6>
                        <h6 style={{textAlign: 'justify'}}>The stated mileage is not guaranteed and is correct to the best of our knowledge. State and federal laws regulate odometer mileage for most used automobiles, but odometer mileage determinations for used engines and engine parts are not practically possible, and are not subject to known state and federal laws. Determination of mileage use for used engines or transmissions cannot be done with certainty because these engines and transmissions are routinely sold and transferred separate and apart from the original vehicle in which they were installed. Therefore all representations of mileage are estimates based upon information and belief.</h6>
                        <h6 style={{textAlign: 'justify'}}>All returned parts or cancelled orders that does not meet the terms and conditions mentioned in the warranty policy are subject to a 25% handling and restocking fees plus the purchaser assumes all shipping costs.</h6>
                        <br></br>
                        <h6><u>Questions or Additional Information</u></h6>
                        <h6 style={{textAlign: 'justify'}}>
                        If you have any questions regarding your warranty or wish to obtain additional information, please e-mail us at customerservice@backtoroadautoparts.com or call us at 1-800-608-3868 and select opt 2
                        </h6>
                    </div> 
                </div>
            </div>
            <Guarantee />
            <Footer />    
        </div>
    );
  }
}

export default Warranty;