import React, { Component } from 'react';
import axios from 'axios';
import {IP} from '../../constants';
import a from './BMW-logo-2000-2048x2048.png';
// import img from '../../images/acura/'
import {Link} from 'react-router-dom';
import './make.css';
class Make extends Component {
    constructor(){
        super();
        this.state = {
            makes : [],
            showview : true,
            partLength :70
        }
    }

    viewall() {
        window.location = 'makes.html';
      }


    componentDidMount () {
        this.getMakeSpecific();
      }

      removeDuplicates(arr) {
        const map = new Map();
        arr.forEach(v => map.set(v.make_name, v)) // having `abc_buildingid` is always unique
        return [...map.values()];
      }

      getMakeSpecific(e) {
        axios.post(IP+'/getMakeSpecific')
          .then( (response) => {
              console.log('nioss',response)
            if (response.status === 200) {
              this.setState({
                  makes : response.data.rows
              })
              localStorage.setItem('allmakes',this.state.makes)
            }
          })
          .catch( (error) => {
            console.log(error);
          });
      }
      enterMakes(id,name){
        this.props.onSelectMake(id,name);  
        window.scrollTo(0, 0);
      }
    //   imageShow(img){ss
    //      return require(img)
    //   }
    // getImage = (props) => {

    //     var a=props;
    //     return <img className="card-img-top partimage" alt="Card image cap" src={require(`${a}`)}/>;
            
    // }
  render() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 col-sm-12  col-lg-12" style={{textAlign:'center'}}>
                <h3 className="text-center">Popular Brands</h3>
                <hr className="bottom"></hr>
                </div>
            </div>
            <div className="row">
                {this.state.makes.slice(10, this.state.partLength).map((make,i) =>
                <div key={i} className="col-lg-1 col-md-2 col-sm-3 forSmallMake" style={{textAlign:'center'}}>
                    {/* <a  onClick={this.enterMakes.bind(this,make.id,make.make_name)} style={{cursor:'pointer'}}>
                        <img className="card-img-top partimageMake" src={require(`./${make.assets}`)}  alt="Card image cap" /> */}
                        {/* {this.getImage(make.assets)} */}
                        {/* <h6 style={{fontWeight:'700'}}>{make.make_name}</h6>
                    </a> */}
                    {/* <Link  to={{pathname: `/make/${make.make_name}`, state: { getmakeid: make.id,getmakename:make.make_name }}}  style={{cursor:'pointer'}}> */}
                        <img className="card-img-top partimageMake" src={require(`./${make.assets}`)}  alt="Card image cap" />
                        {/* {this.getImage(make.assets)} */}
                        <h6 style={{fontWeight:'700',color:'black'}}>{make.make_name}</h6>
                    {/* </Link> */}
                </div>
                )}
            </div>
        </div>
    );
  }
}

export default Make;