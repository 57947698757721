import React, { Component } from 'react';
import './search.scss';
import axios from 'axios';
// import search from './search.png';
import {IP} from '../../constants';
import {Link} from 'react-router-dom';
import gif from '../../btr-gif.gif';
import tick from './tick.png';
class Search extends Component {
  constructor(props){
    super(props);
    this.state={
      yearStatus : false,
      makeStatus : true,
      modelStatus : true,
      partStatus : true,
      yearval : [],
      makeval : [],
      modelval : [],
      partval : [] ,
      yearshow : '',
      makeshow : '',
      modelshow : '',
      partshow : '',
      activate : 0,
      subpartval : [],
      subPartShow : '',
      yearSelected : null,
      makeSelected : null,
      modelSelected : null,
      partSelected : null,
      showgif : 0,
      showHead : 'SELECT',
      showMain : 'YEAR',
      showTail : 'to BEGIN SEARCHING for PARTS',
      showDetails : 0
    }
  }
  // componentWillMount(){
  //   window.location = 'about.html';

  // }
  componentDidMount () {
    
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.makename){
      this.setState({
        makeshow : nextProps.makename
      });
    }
    if(nextProps.makeid){
      
      this.getYearByMake(nextProps.makename);
    }
  }
  getYearByMake(make){
    axios.post(IP+'/getYearByMake/'+make)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            activate : 1,
            showHead : 'SELECT',
            showMain : 'YEAR',
            showTail : 'to BEGIN SEARCHING for PARTS',
            yearval : response.data,
            showgif : 0,
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }
  getYear() {
    this.setState({
      showgif : 1,
      showHead : 'SELECT',
      showMain : 'YEAR',
      showTail : 'to BEGIN SEARCHING for PARTS'

    });

    axios.post(IP+'/year')
      .then( (response) => {
        console.log(response)
        if (response.status === 200) {
          this.setState({
            yearval : response.data,
            showgif : 0,
            // activate:1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getMake(year){
    this.setState({
      yearshow : year,
      yearSelected : 1,
      showgif : 1,
      showHead : 'SELECT',
      showMain : 'MAKE',
      showTail : ''
    });

    // localStorage.setItem('year', year);
    axios.post(IP+'/make/'+year)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            makeval : response.data,
            makeStatus : false,
            showgif : 0,
            activate:2
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getModel(id, make_name){
    this.setState({
      makeshow : make_name,
      makeSelected : 1,
      showgif : 1,
      showHead : 'SELECT',
      showMain : 'MODEL',
      showTail : ''
    });
    // localStorage.setItem('make', make_name);
    axios.post(IP+'/model/'+id)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            modelval : response.data,
            modelStatus : false,
            showgif : 0,
            activate:3
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getPart(model_name){
    this.setState({
      modelshow : model_name,
      modelSelected : 1,
      showgif : 1,
      showHead : 'SELECT',
      showMain : 'PART',
      showTail : ''
    });
    // localStorage.setItem('model', model_name);
    axios.post(IP+'/parts/')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            partval : response.data,
            partStatus : false,
            showgif : 0,
            activate:4
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }
  getSubPart(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('part', e.nativeEvent.target[index].text);

    this.setState({
      subPartShow : e.nativeEvent.target[index].text,
      partshow : e.nativeEvent.target[index].text,
      partSelected : 1,
      activate : 4
    })
    // axios.post(IP+'/subparts/'+e.target.value)
    //   .then( (response) => {
    //     console.log('sdsdsd',response)
    //     if (response.status === 200) {
    //       this.setState({
    //         subpartval : response.data,
    //         activatesub : 5,
    //         showgif : 0
    //       });
    //       if(response.data.length === 0){
    //         this.setState({
    //           showgif : 0
    //         })
    //       }
    //     }
    //   })
    //   .catch( (error) => {
    //     console.log(error);
    //   });
  }

  yearfocus(){
    this.getYear();
    this.setState({
      showDetails : 1,
      activate : 1,
      showHead : 'SELECT',
      showMain : 'YEAR',
      showTail : 'to BEGIN SEARCHING for PARTS'
    });
  }
  makefocus(){
    this.setState({
      activate : 2,
      showHead : 'SELECT',
      showMain : 'MAKE',
      showTail : ''
    });
  }
  modelfocus(){
    this.setState({
      activate : 3,
      showHead : 'SELECT',
      showMain : 'MODEL',
      showTail : ''
    });
  }
  partfocus(){
    this.setState({
      activate : 4,
      showHead : 'SELECT',
      showMain : 'PART',
      showTail : ''
    });
}

  // getProduct(name){
  //   // localStorage.setItem('subpart',name);
  //   window.location = '/GetQuoteNow';
  //   // this.context.router.replace('/GetQuoteNow');
    
  // }

  // getProducts(){
  //   //window.location = 'sendmail.html';
  //   // this.context.router.replace('/GetQuoteNow');
  //   window.location = '/GetQuoteNow';
  // }

  render() {
    return (
      <div className="App">
          <div className="main-row showOpacity ">
              <div className="container-search ">
                  <h2 style={{color: '#ffbf3c',opacity:'0.99'}} >GET QUOTES FOR USED AUTO AND TRUCK PARTS</h2>
                  <div className="row" >
                    <div className="col-lg-3   hidden-xs">
                        <input type="number" disabled={this.state.yearStatus}
                        style={{fontSize:18,fontWeight:'100',width: '100%',height: 44,borderRadius:20,
                        backgroundImage:this.state.yearshow !== '' ? "url(" + tick + ")" : '',backgroundRepeat:'no-repeat',backgroundSize:'contain',
                        backgroundPosition: 'right',backgroundColor:this.state.yearshow !== '' ? 'rgba(255, 255, 255, 0.7)' : '#fff',
                        boxShadow: this.state.activate === 1 ? '0 0 0 0.1rem #DA5252': ''}}
                        onChange={(val)=>this.yeartype.bind(this)}
                        onFocus={this.yearfocus.bind(this)}
                        value={this.state.yearshow}
                        readOnly="readonly"
                        className="form-control search" id="search" placeholder="Vehicle Year"/>
                        <span class="glyphicon glyphicon-user form-control-feedback"></span>
                    </div>
                    <div className="col-lg-3">
                        <input type="text" disabled={this.state.makeStatus}
                        style={{fontSize:18,fontWeight:'100',width: '100%',height: 44,borderRadius:20,
                        backgroundImage:this.state.makeshow !== '' || undefined || null  ? "url(" + tick + ")" : '',backgroundRepeat:'no-repeat',backgroundSize:'contain',
                        backgroundPosition: 'right',backgroundColor:this.state.makeshow !== '' ? 'rgba(255, 255, 255, 0.7)' : '#fff',
                        boxShadow: this.state.activate === 2 ? '0 0 0 0.1rem #DA5252': ''}}
                        value={this.state.makeshow}
                        onFocus={this.makefocus.bind(this)}
                        readOnly="readonly"
                        className="form-control search" id="exampleInputEmail1" placeholder="Vehicle Make"/>
                    </div>
                    <div className="col-lg-3">
                        <input type="text" disabled={this.state.modelStatus}
                        style={{fontSize:18,fontWeight:'100',width: '100%',height: 44,borderRadius:20,
                        backgroundImage:this.state.modelshow !== '' ? "url(" + tick + ")" : '',backgroundRepeat:'no-repeat',backgroundSize:'contain',
                        backgroundPosition: 'right',backgroundColor:this.state.modelshow !== '' ? 'rgba(255, 255, 255, 0.7)' : '#fff',
                        boxShadow: this.state.activate === 3 ? '0 0 0 0.1rem #DA5252': ''}}
                        value={this.state.modelshow}
                        onFocus={this.modelfocus.bind(this)}
                        readOnly="readonly" className="form-control search"
                        placeholder="Vehicle Model"/>
                    </div>
                    <div className="col-lg-3">
                        <input type="text" disabled={this.state.partStatus}
                        style={{fontSize:18,fontWeight:'100',width: '100%',height: 44,borderRadius:20,
                        backgroundImage:this.state.partshow !== '' ? "url(" + tick + ")" : '',backgroundRepeat:'no-repeat',backgroundSize:'contain',
                        backgroundPosition: 'right',backgroundColor:this.state.partshow !== '' ? 'rgba(255, 255, 255, 0.7)' : '#fff',
                        boxShadow: this.state.activate === 4 ? '0 0 0 0.1rem #DA5252': ''}}
                        value={this.state.partshow}
                        onFocus={this.partfocus.bind(this)}
                        readOnly="readonly" className="form-control search" id="exampleInputEmail1" placeholder="Part Name"/>
                    </div>
                  </div>
              </div>
          </div>
          {this.state.showDetails === 1 ? 
          (
            <div className="main-rowbox">
          <div className="container-search showOpacity" 
          style={{}} >
            <div className="row">
            <h3 className={this.state.activate === 1 || this.state.activate === 2 || this.state.activate === 3 || this.state.activate === 4 ? 'blink':''} style={{fontWeight:'700',fontSize:22,margin:'1%',color:'#A63434'}}>
              <span>{this.state.showHead} </span>
              <span style={{fontWeight:'1000'}}>{this.state.showMain} </span>
              <span>{this.state.showTail}</span>
            </h3>
              
            </div>
            {this.state.showgif === 0 ? 
            <div className="row" style={{paddingBottom:'1rem'}}>
            {this.state.activate === 1 || this.state.activate === 0 ?
              this.state.yearval.map((year,i) =>
              <div key={i} className="col-sm-6 col-md-2 col-lg-1" style={{padding:'0.2rem',paddingLeft:'1rem'}}>
                <a onClick={this.getMake.bind(this, year.year_name)} style={{fontSize : 20,color:'white',cursor:'pointer'}} >{year.year_name}</a>
              </div>) :
              this.state.activate === 2 ?
              this.state.makeval.map((make,i) =>
              <div key={i} className="col-sm-6 col-md-2 col-lg-2" style={{padding:'0.2rem',paddingLeft:'1rem'}}>
                <a onClick={this.getModel.bind(this, make.id, make.make_name)} style={{fontSize : 20,color:'white',cursor:'pointer'}} >{make.make_name}</a>
              </div>) :
              this.state.activate === 3 ?
              this.state.modelval.map((model,i) =>
              <div key={i} className="col-sm-6 col-md-2 col-lg-2" style={{padding:'0.2rem',paddingLeft:'1rem'}}>
                <a onClick={this.getPart.bind(this, model.model_name)} style={{fontSize : 20,color:'white',cursor:'pointer'}} >{model.model_name}</a>
              </div>) :
              this.state.activate === 4 ?
              (<div style={{padding:'5%'}} className="col-sm-12 col-md-12 col-lg-12">
                <select
              className="custom-select" onChange={this.getSubPart.bind(this)}  id="inlineFormCustomSelect">
              <option disabled selected>Choose Auto Part</option>
              {this.state.partval ===null ?
              <option  disabled selected>Choose Auto Part</option> :
              this.state.partval.map(function(part,key){
                  return(<option key={key} value={part.id}>{ part.part_name }</option>)
              })}
              </select>
              <div className="row" style={{marginTop:8,backgroundColor:'rgba(0,0,0,0.7)'}}>

              {this.state.activatesub === 5 ?
                this.state.subpartval.map((sub,i) =>
                <div key={i} className="col-sm-6 col-md-6 col-lg-6" style={{textAlign:'center'}}>
                  <Link 
                  to={{ pathname: '/GetQuoteNow/', state: { make: this.state.makeshow,model: this.state.modelshow,
                    parts: this.state.partshow, year:this.state.yearshow, subpart : sub.sub_name
                  } }}
                  style={{fontSize : 22,color:'white',cursor:'pointer'}} >{sub.sub_name}</Link>
                </div>
                )
              : ''}
              </div>
              </div>


              ) : ''
          }
          <div className="col-md-6 col-lg-6 offset-md-4 offset-lg-4">

                {this.state.yearSelected === null || this.state.makeSelected === null || this.state.modelSelected === null
                || this.state.partSelected === null ?
                ''
                  :
                              <Link

                             className="btn cardBtn" style={{width:'60%'}}
                            to={{ pathname: '/GetQuoteNow/', state: { make: this.state.makeshow,model: this.state.modelshow,
                              parts: this.state.partshow, year:this.state.yearshow, subpart : this.state.subPartShow
                            } }}  >
                            GET YOUR PART NOW !</Link>
                  }
              </div>
            </div>  :
            <div className="row">
              <div class="col-md-4 offset-md-4 col-sm-12 col-lg-4 offset-lg-4">
                <img style={{height:159,width:'100%'}} src={gif} alt="Card image cap"/>
              </div>  
            </div> 
          }
          </div>
        </div> 
          ) : ''}
      </div>
    );
  }
}

export default Search;

