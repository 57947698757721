import React, {Component} from 'react';
import './css/login.css';
import {Link} from 'react-router-dom';
class Login extends Component {
    constructor(){
        super();
        this.state ={
            email : null,
            password : null
        }
    }
    Login(){
        if(this.state.email === "santo94" && this.state.password === "santo94") {
            window.location = '/dashboard'
        } else {
            alert('wrong credentials')
        }
    }
    username(val){
        this.setState({
            email : val.target.value
        });
    }
    password(val){
        this.setState({
            password : val.target.value
        });
    }
 render() {
     return (
         <div className="Login" style={{height:'100vh'}}>
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <div className="fadeIn first">
                        {/* <img src="http://danielzawadzki.com/codepen/01/icon.svg" id="icon" alt="User Icon" /> */}
                        <h1>&nbsp;</h1>
                    </div>
                    <form>
                        <input type="text" onChange={this.username.bind(this)} id="login" className="fadeIn second" name="login" placeholder="login"/>
                        <input type="text" onChange={this.password.bind(this)} id="password" className="fadeIn third" name="login" placeholder="password"/>
                        <br></br>
                        <button  type="button" onClick={this.Login.bind(this)} className="btn btn-primary">Login</button>
                    </form>
                    <div id="formFooter">
                        <a className="underlineHover" href="#">Forgot Password?</a>
                    </div>

                </div>
            </div>
         </div>
     )
 }   
}

export default Login;