import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import Guarantee from './components/guarantee';
class FAQ extends Component {
  render() {
    return (
        <div className="FAQ">
            <Header/>
            {/* <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={about} style={{height:270}} alt="about"/>
                    </div>
                </div>
            </div> */}
            <div className="container-fluid">
                <div className="row"
                    style={{backgroundColor: '#DA5252',height: 100,backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',flex: 1,justifyContent: 'center',alignItems: 'center'}}>
                    <div className="container">
                        <h1 className="text-white"><b>Frequently Asked Questions</b></h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid" style={{marginTop: '1%',marginBottom:'1%'}}>
                <div className="row"
                    style={{backgroundImage: "url(" + bg + ")",height: 'auto',backgroundRepeat: 'repeat',backgroundSize: 394,backgroundColor: 'rgba(255,255,255,0.4)'}}>
                    <br></br>
                    <div className="col-md-8 offset-md-2 col-sm-12 col-lg-8 offset-lg-2">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link btnCollapseFont" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What am I getting?
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                    All the part we sell are Used OEM parts, which are checked and inspected and is of good usable quality.
                                            Green parts , also known as ‘recycled parts’ or ‘used parts’ are official manufacturer (OEM) parts. So, the
                                replacement we provide will be the same part that was manufactured by the manufacturer of your
                                vehicle.
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Why should I go for green parts versus new parts?
                                    </button>
                                </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                        One simply cannot afford to pay big sums for new parts direct from the manufacturer. Also, lot of parts
                                        for older vehicles, are out of stock at the manufacturer and what is available as “new” are aftermarket
                                        replacements. Green parts are the answer as they can be up to 50% less than going direct to your
                                        vehicle manufacturer. Choosing recycled parts doesn’t leave you with a narrow choice, instead there are
                                        an abundance of parts available including Ford, Ferrari and just about every other manufacturer in
                                        between.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Do we make sure of the quality of used parts before shipment?
                                    </button>
                                </h5>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    The Motor Vehicle Dismantlers Association (MVDA) has issued news that it will be adopting and
                                    promoting the ARA Parts Standards and BSI’s PAS777. The Automotive Recyclers Association of America
                                    (ARA) parts standard has helped green part use to become more widespread in the insurance repair
                                    industry in the US. Here at BackToRoad Auto, for almost all the parts we sell, we try to follow the ARA
                                    parts standard and grading. So, if you purchase a green part, it will undertake quality tests to ensure that
                                    the part is fit for user needs. We also provide you a standard warranty to further enhance peace of mind
                                    of the purchaser. Hence, it would be unfair to expect that a green part would not measure up to a new
                                    part as it does.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    What is the procedure for placing an order?
                                    </button>
                                </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    The usual procedure for placing an order would include customers intimating us with requests for auto
                                    parts online or directly contacting us wherein our Senior Auto Parts Specialists, matches all the
                                    specifications of the Auto Part with our catalogues, either with your VIN or by asking some industry
                                    standard relevant questions to narrow down the part. Then the order is again verified by a “Verifier”,
                                    following which we accept payment, sends the invoice to the customer and ships the part out.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#two" aria-expanded="false" aria-controls="two">
                                    What warranty do I get?
                                    </button>
                                </h5>
                                </div>
                                <div id="two" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    We provide a standard 30 days warranty for every part we sell, from the day you receive it. If the part
                                    we deliver is incorrect, defective or damaged, we do take the part back and get you a replacement or a
                                    refund.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#three" aria-expanded="false" aria-controls="three">
                                    If I get a defective or a wrong part, how do I return it?
                                    </button>
                                </h5>
                                </div>
                                <div id="three" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    If you find out that you received a wrong part or a defective one, the first thing you should do is to call
                                    us and if we are not available (out of business hours), send us an Email. Once you intimate us, our
                                    Authorized Personnel will reach you and provide you the guidelines to return the part back and send you
                                    the RMA (Return Merchandise Authorization) form.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#four" aria-expanded="false" aria-controls="four">
                                    How long does it take for the shipment?
                                    </button>
                                </h5>
                                </div>
                                <div id="four" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    Once we get the order from you, your auto part is once again quality checked and inspected and
                                    shipped usually in 24 – 48 hours. For the non-freight auto parts we sell, the average time frame we show
                                    on paper is 5 -7 business days for a free regular shipping, but you might get it sooner as well. For the
                                    freight auto parts we sell, the average time frame is 7 – 10 business days. We also have expedite
                                    options – delivery in 3 business days, 2 business days and 1 business day
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#five" aria-expanded="false" aria-controls="five">
                                    How do I track my shipment?
                                    </button>
                                </h5>
                                </div>
                                <div id="five" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    We will send you the Shipment Tracking Number to your Email provided at the time of placing the order.
                                    In case you do not have an Email, we will call you to provide the Tracking number.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#six" aria-expanded="false" aria-controls="six">
                                    Why was I told that BackToRoad Auto does not promote shipping to a residential address other than
                                    my billing address?
                                    </button>
                                </h5>
                                </div>
                                <div id="six" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    Here at BackToRoad Auto, we take extreme measures to prevent fraud. So, for debit card/credit card
                                    payments, our general principle is to ship to the customer’s billing address, which usually is the
                                    customer’s primary residential address. However, If the customer needs the part to be be shipped to a
                                    registered business address, we are more than happily willing to do so.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link btnCollapseFont collapsed" type="button" data-toggle="collapse" data-target="#seven" aria-expanded="false" aria-controls="seven">
                                            Do I have the option of picking up my part?
                                    </button>
                                </h5>
                                </div>
                                <div id="seven" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    Unfortunately, no. We are a professional warehouse (which is different from a store) and we deliver
                                    nationwide. So, we do not provide an option of picking up the part, instead we prefer the customer to sit at the comfort of his home and we deliver it to the customers residence or to his mechanic’s auto
                                    shop.
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Guarantee />
            <Footer />    
        </div>
    );
  }
}

export default FAQ;
