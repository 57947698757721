import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Ford from '../../images/nissantra.jpeg';
import gif from '../btr-gif.gif';
class NissanTransmissionManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Nissan',
            makeid : 132,
            selectedpartid : 1,
            selectedpartname : 'Transmission',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Nissan'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    :
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Nissan Transmissions</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Nissan Transmissions in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Nissan Transmissions
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Nissan Transmissions </u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Ford} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Performance checked and quality assured Used Nissan Transmissions 
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Nissan models
                                                    </h6>
                                                    <br></br>
                                                    <h6><strong>Buy Quality Nissan Transmissions Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you are 1975 born or so then you might recall economical, peppy little cars that were famously known
                                                        as “Datsuns”. Those of you who are even older than that may recall a slick little sports roadster that was
                                                        known as “Fairlady”, which was another Datsun Marque and was the first Japanese cars that were ever
                                                        sold in America. Presently that company is known as Nissan, which is famous for vehicles that are
                                                        reliable and economical.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you own a <u>Pathfinder</u>, <u>Rogue</u>, <u>Nissan Pickup</u>, <u>Maxima</u>, <u>Stanza</u> or other Nissan model, BackToRoad
                                                        Auto Parts has high quality <strong>used Nissan parts</strong> to suit every vehicle. Mechanics, garage owners or dealers
                                                        can also turn to Chuck and Eddies for everything from engine replacements to used transmissions,
                                                        alternators, wheels and more. Whether you’re repairing a Nissan or just driving one with loved ones in
                                                        tow, enjoy peace of mind knowing that used Nissan parts from BackToRoad Auto Parts are dependable
                                                        and long lasting. That’s good for your car or your car business!
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        The catalogue of <strong>used Nissan parts</strong> at BackToRoad Auto Parts is always full with the best ones from the
                                                        <strong>junkyards</strong> and they can be ordered for wholesale prices. We carry only the absolute best pieces in the
                                                        <strong>auto parts</strong>.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Instead of dealing with the hassle of a dealer, you can choose to opt for our easy shopping of <strong>Nissan
                                                        parts online</strong>, individually or in a package as per your convenience and car needs.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Auto Parts are foremost experts in providing fast, reasonably priced and optimally
                                                        functional used Nissan parts. Our enormous inventory of small and large auto parts allows our customers
                                                        to benefit from tremendous savings. Enjoy quick and affordable worldwide shipping, you’re your auto
                                                        parts will arrive at your doorstep in pristine, fully operational condition, ready to be installed in your
                                                        Nissan.
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default NissanTransmissionManagement;