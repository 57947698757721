import React, { Component } from 'react';
import axios from 'axios';
// import search from './search.png';
import {IP} from '../constants';
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import gif from '../btr-gif.gif';
class SearchMake extends Component {
  constructor(props){
    super(props);
    this.state={
      yearval : [],
      makeval : [],
      modelval : [],
      partval : [] ,
      subpartval : [],
      partlocal : '',
      partLength : 50,
      showview: true,
      partvalsearch : '',
      searchLength : 0,
      selectedPartId : null,
      selectedPartVal : null,
      yearSelected : null,
      partSelected : null,
      makeSelected : null,
      modelSelected : null,
      subpartSelected : null,
      yearnew : null,
      modelnew : null,
      subPartsnew : null,
      changeCheck : 0,
      yearBlink : 0,
      make_name : null,
      make_id : null,
      makenew : null,
      makenews : null,
      name : null,
      email : null,
      phone : null,
      zip : null,
      stockOrNot: 0,
      status: false
    }
  }
  // componentWillMount(){
  //   window.location = 'about.html';

  // }
  componentDidMount () {
    this.getYear();   
  }
  componentWillMount(){
    
  }
  subPartsNew (){
    axios.post(IP+'/subparts/'+localStorage.getItem('partid'))
    .then( (response) => {
      if (response.status === 200) {
        this.setState({
          subpartval : response.data,
          partSelected : 1
        });
      }
    })
    .catch( (error) => {
      console.log(error);
    });
  }
  getYear(e) {
    axios.post(IP+'/year')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            yearval : response.data,

          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getMake(e){
    // localStorage.setItem('year', e.target.value);
    this.setState({
      yearnew : e.target.value,
      yearBlink : 0
    });
    axios.post(IP+'/make/'+e.target.value)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            makeval : response.data,
            yearSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getModel(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('make', e.nativeEvent.target[index].text);
    this.setState({
      makenews : e.nativeEvent.target[index].text
    });
    axios.post(IP+'/model/'+e.target.value)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            modelval : response.data,
            makeSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getPart(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('model', e.nativeEvent.target[index].text);
    this.setState({
      modelnew : e.nativeEvent.target[index].text
    });
    axios.post(IP+'/parts/')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            partval : response.data,
            modelSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }
  componentDidUpdate(){

  }
  componentWillReceiveProps(nextProps){
    if(nextProps.partid){
      this.setState({
        selectedPartVal : nextProps.partname,
        yearBlink:1
      });
    }
    if(nextProps.view){
      if(nextProps.view=== 1){
        this.setState({
          yearBlink : 1
        });
      }
    }
    if(nextProps.makeid){
      this.setState({
        makenews: nextProps.makename,
        yearBlink : 1
      });
      // this.getYearByMake(nextProps.makename);
      axios.post(IP+'/getYearByMake/'+nextProps.makename)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            yearval : response.data,
            yearBlink : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
      axios.post(IP+'/model/'+nextProps.makeid)
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            modelval : response.data,
            makeSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
    }
  }
  getSubPart(e){
    var index = e.nativeEvent.target.selectedIndex;
    // localStorage.setItem('part', e.nativeEvent.target[index].text);
    this.setState({
      selectedPartVal : e.nativeEvent.target[index].text,
      partSelected : 1,
      changeCheck : 1
    });
  }

  getName(val){
    this.setState({
      name : val.target.value
    });
  }


  getPhone(val){
    this.setState({
      phone : val.target.value
    })
  }
  getEmail(val){
    this.setState({
      email : val.target.value
    })
  }
  getZip(val){
    this.setState({
      zip : val.target.value
    })
  }

  sendMailTo(){
    const regx = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email);
    if(this.state.yearnew === '' || this.state.yearnew === null || this.state.yearnew === undefined) {
      this.setState({
        yearBlink : 1
      });
      toast.info("Please Select Year", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } else if(this.state.modelnew === '' || this.state.modelnew === null || this.state.modelnew === undefined) {
      this.setState({
        yearBlink : 3
      });
      toast.info("Please Select Model", {
        position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
      });
    } 
    // else if(this.state.name === '' || this.state.name === null || this.state.name === undefined) {
    //   this.setState({
    //     yearBlink : 5
    //   });
    //   toast.info("Please Enter Your Name", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //       className: 'foo-bar'
    //   });
    // } else if(this.state.email === '' || this.state.email === null || this.state.email === undefined ) {
    //     this.setState({
    //       yearBlink : 6
    //     });
    //     toast.info("Please enter a email for Email Quote", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //         className: 'foo-bar'
    //     });
    // } else if(regx === false) {
    //     this.setState({
    //       yearBlink : 6
    //     });
    //     toast.info("Please enter a valid email for Email Quote", {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //         className: 'foo-bar'
    //     });
    // } 
    else {
      this.setState({
        status: true
      });
      axios.post(IP+'/mailservice',
        {
      'year' : this.state.yearnew === '' ? null : this.state.yearnew,
      'make' : this.state.makenews ? this.state.makenews  : null,
      'model' : this.state.modelnew ,
      'drive' : null,
      'engine' : null,
      'part' : this.state.selectedPartVal ? this.state.selectedPartVal  : '',
      'interchange' : null,
      'application' : '',
      'name' : this.state.name === '' ? null : this.state.name,
      'phone' : this.state.phone === '' ? null : this.state.phone,
      'email' : this.state.email === '' ? null : this.state.email,
      'text' : null,
      'location' : this.state.zip ,
      'notes' : this.state.notes === '' ? null : this.state.notes,
      
        }
      )
      .then( (response) => {
          console.log('enth',response)
          this.setState({
            status: true
          });
        if(response.data.status === 200){
          window.location="/EntryToGet";
        }
      })
      .catch( (error) => {
        console.log(error);
      });
    } 
  }

  render() {
    return (

                <div className="card" >
                    <div className="card-header" style={{background:'#ffbf3c'}}>
                        <h4 className=" " style={{textAlign:'center', color:'black', fontWeight:'900'}}>Get a Free Quote Now!</h4>
                    </div>
                    <div className="card-body" style={{backgroundColor:"#6e7476"}}>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className={this.state.yearBlink === 1 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 1 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getMake.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} className="blink" >Year</option>
                                    {this.state.yearval.map((e, key) => {
                                        return <option key={key} value={e.year_name}>{e.year_name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className={this.state.yearBlink === 2 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 2 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getModel.bind(this)}>
                                {this.state.makenews === null || undefined ?
                                    <option >{this.state.makenews}</option>:
                                    <option >{this.state.makenews}</option>
                                     }
                                  {this.state.makeval.map((e, key) => {
                                      return this.state.makenews === e.make_name ? '':
                                      <option key={key} value={e.id}>{e.make_name}</option>;
                                  })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className={this.state.yearBlink === 3 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 3 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getPart.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} >Model</option>
                                    {this.state.modelval.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.model_name}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <select className={this.state.yearBlink === 4 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 4 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}} onChange={this.getSubPart.bind(this)}>
                                    {this.state.selectedPartVal === null ?
                                    <option disabled selected="selected" style={{color:'white'}} >Part Name</option>:
                                    <option >{this.state.selectedPartVal}</option>
                                     }
                                  {this.state.partval.map((e, key) => {
                                      return <option key={key} value={e.id}>{e.part_name}</option>;
                                  })}
                                </select>
                            </div>
                        </div>
                        {
                          this.state.yearnew !== null && this.state.makenews !== null && this.state.modelnew !== null && this.state.selectedPartVal !== null ?
                          <div className="row">
                              <div className="col-sm-12 col-lg-12 col-md-12">
                                <h5 style={{color:'#6fcc6f',textAlign:'end',fontWeight:'900'}}><i><span style={{fontSize: 20,fontWeight: '900'}}>✓</span> In Stock</i></h5>
                              </div>
                          </div>
                          : ''
                        }
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <label style={{color:'#fff'}}>Name <span style={{color:'red'}}>* </span></label>
                                <input type="text"  value={this.state.name} className={this.state.yearBlink === 5 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 5 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}}
                                    onChange={this.getName.bind(this)} id="inlineFormInput" placeholder="Name" ></input>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-12 col-md-12">
                              <label style={{color:'#fff'}}>Email <span style={{color:'red'}}>* </span></label>
                                <input type="email" value={this.state.email} className={this.state.yearBlink === 6 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 6 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}}
                                    onChange={this.getEmail.bind(this)} id="inlineFormInput" placeholder="Email-id"></input>
                                <span style={{color:'#fff'}}>Get Email Quote with pictures</span>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom:'5%'}}>
                            <div className="col-sm-12 col-lg-8 col-md-8">
                              <label style={{color:'#fff'}}>Phone <span style={{color:'red'}}>* </span></label>
                                <input type="number" min="11" max="11" value={this.state.phone} className={this.state.yearBlink === 7 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 7 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}}
                                    onChange={this.getPhone.bind(this)} id="inlineFormInput" placeholder="Phone Number"></input>
                                <span style={{color:'#fff'}}>For quote assistance only</span>
                            </div>
                            <div className="col-sm-12 col-lg-4 col-md-4">
                                  <label style={{color:'#fff'}}>ZIP</label>
                                  <input type="number" value={this.state.zip} className={this.state.yearBlink === 8 ? 'form-control blink' : 'form-control'} style={{border: this.state.yearBlink === 8 ? '2px solid red' :'2px solid #fff',borderRadius:0, height:'calc(2.8rem + 2px)',backgroundColor:'#6e7476', color:'#fff', fontWeight:'600'}}
                                    onChange={this.getZip.bind(this)} id="inlineFormInput" placeholder="ZIP" ></input>
                            </div>
                        </div>
                        {/* {this.state.subpartval.length === 0 ?
                          '' :
                          <div className="row">
                              <div className="col-sm-12 col-lg-12 col-md-12">
                                  <label style={{fontSize : 12}}>Sub Part(Optional)</label>
                                  <select className="form-control" onChange={this.subParts.bind(this)}>
                                    <option disabled selected="selected" style={{color:'white'}} >Choose Sub Part</option>
                                    {this.state.subpartval.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.sub_name}</option>;
                                    })}
                                  </select>
                              </div>
                          </div>
                        } */}
                        <div className="row">
                            <div className="col-sm-12 col-lg-12 col-md-12">
                            {/* {this.state.yearSelected === null || this.state.makeSelected === null || this.state.modelSelected === null
                                || this.state.partSelected === null || this.state.name === null || this.state.email === null 
                                || this.state.phone === null || this.state.zip === null ?
                                <button className="btn disabled"
                                style={{background:'rgb(251, 208, 18)',color:'black',width:'100%',height:45,fontSize:20}}><strong><i class="fa fa-search"></i>  Find My Part Now !</strong></button>
                                 : */}
                                {/* //  <button onClick={this.getProduct.bind(this)} className="btn" */}
                                {/* // style={{background:'rgb(251, 208, 18)',color:'black'}}>GET YOUR PART</button> */}
                                {
                                  this.state.status === false ?
                                    <button className="btn cardBtn" onClick={this.sendMailTo.bind(this)} style={{background:'#ffbf3c',color:'black',width:'100%',height:45,fontSize:20}}>
                                      <strong>
                                      Find My Part Now !
                                      </strong>
                                    </button>
                                    :
                                    <div className="" style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center',background:'transparent'}}>
                                        <img src={gif} style={{height:'48px'}} alt="Card image cap"/>
                                    </div>
                                }
                                {/* //  } */}

                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
    );
  }
}

export default SearchMake;