import React, {Component} from 'react';
import HeaderDashboard from '../dashboard/header/index';
import axios from 'axios';
import {IP} from '../../constants';
class RecentPartManagement extends Component {
    constructor(){
        super();
        this.state = {
            file : null,
            year : null,
            make : null,
            model : null,
            partid : null,
            specification : null,
            morespecification : null,
            partType : null,
            returnData : [],
            selectedData : {},
            showid : null
        }
    }
    componentDidMount(){
        this.getProducts();
    }
    getProducts() {
        axios.post(IP+'/getLatestProducts')
            .then( (response) => {
                this.setState({
                    returnData : response.data
                });
            })
            .catch( (error) => {
                console.log(error);
            });
    }
    onFormSubmit(e){
        e.preventDefault();
        const formData = new FormData();
        formData.append('image',this.state.file);
        formData.append('make',this.state.make);
        formData.append('model',this.state.model);
        formData.append('year',this.state.year);
        formData.append('partid',this.state.partid);
        formData.append('specification',this.state.specification);
        formData.append('morespecification',this.state.morespecification);
        formData.append('partType',this.state.partType);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post(IP+"/upload/parts",formData,config)
            .then((response) => {
                if(response.status == 200){
                    window.location="/RecentPartManagement"
                }
            }).catch((error) => {
                console.log('sdsd')
        });
    }

    onFormSubmitEdit(e){
        e.preventDefault();
        // var data = {
        //     'make': this.state.make,
        //     'model': this.state.model,
        //     'year': this.state.year,
        //     'partid': this.state.partid,
        //     'specification': this.state.specification,
        //     'morespecification': this.state.morespecification,
        //     'partType': this.state.partType
        // }
        axios.post(IP+"/update/parts",{
            'make': this.state.make,
            'model': this.state.model,
            'year': this.state.year,
            'partid': this.state.partid,
            'specification': this.state.specification,
            'morespecification': this.state.morespecification,
            'partType': this.state.partType,
            'id': this.state.showid
        })
            .then((response) => {
                if(response.status == 200){
                    window.location="/RecentPartManagement"
                }
            }).catch((error) => {
                console.log('sdsd')
        });
    }

    getMake(val){
        this.setState({
            make : val.target.value
        });
    }
    getModel(val){
        this.setState({
            model : val.target.value
        });
    }
    getPartId(val){
        this.setState({
            partid : val.target.value
        });
    }
    selectPart(val){
        this.setState({
            partType : val.target.value
        });
    }
    getYear(val){
        this.setState({
            year : val.target.value
        });
    }
    getSpecification(val){
        this.setState({
            specification : val.target.value
        });
    }
    getMoreSpecification(val){
        this.setState({
            morespecification : val.target.value
        });
    }
    onChangeFile(e) {
        this.setState({file:e.target.files[0]});
    }
    deleteSingle(id){
        axios.post(IP+'/delete/products/'+id)
          .then( (response) => {
              console.log('nioss',response)
            if (response.status === 200) {
              window.location="/RecentPartManagement"
            }
          })
          .catch( (error) => {
            console.log(error);
          });
    }
    selectPartSearch(val){
        axios.post(IP+'/getSpecificLatestProducts/'+val.target.value)
            .then( (response) => {
                console.log('sdsd',response)
                this.setState({
                    returnData : response.data
                });
            })
            .catch( (error) => {
                console.log(error);
            });
    }
    EditSingle(val){
        axios.post(IP+'/edit/products/'+val)
        .then( (response) => {
            this.setState({
                partid : response.data.partid,
                make : response.data.make,
                model : response.data.model,
                specification : response.data.specification,
                year : response.data.year,
                morespecification : response.data.morespecification,
                partType : response.data.partType,
                showid : val
            });
        })
        .catch( (error) => {
          console.log(error);
        });
    }
    render () {
        return (
            <div className="RecentPartManagement">
                <HeaderDashboard />
                <div className="container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="col-sm-4 col-lg-4 col-md-4">
                                <br></br>
                                <button type="button" class="btn" style={{backgroundColor:'rgb(255, 191, 60)'}} data-toggle="modal" data-target="#exampleModal">
                                    Add New Part
                                </button>
                            </div>
                            <div className="col-sm-8 col-md-5 col-lg-5">
                                <div class="form-group">
                                    <label for="exampleFormControlSelect1">Part Type</label>
                                    <select onChange={this.selectPartSearch.bind(this)} class="form-control" id="exampleFormControlSelect1">
                                        <option disabled selected="selected" >Choose Part Type</option>
                                        <option value="Engines">Engines</option>
                                        <option  value="Steering Column">Steering Column</option>
                                        <option value="Transmission">Transmission</option>
                                        <option value="A.B.S">A.B.S</option>
                                        <option value="Axle Assemblies">Axle Assemblies</option>
                                        <option value="Alternator">Alternator</option>
                                        <option value="Transfer Case">Transfer Case</option>
                                        <option value="Struts">Struts</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Pic</th>
                            <th scope="col">Make</th>
                            <th scope="col">Year</th>
                            <th scope="col">Model</th>
                            <th scope="col">Part Id</th>
                            <th scope="col">Specification</th>
                            <th scope="col">More Specification</th>
                            <th scope="col">Part Type</th>
                            <th scope="col">Delete</th>
                            <th scope="col">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.returnData.map((sub,i) =>
                                <tr>
                                    <td><img style={{width:24}} src={'/uploads/'+sub.image}/></td>
                                    {/* <td><img src={require(`${sub.image}`)}  alt="Card image cap" /></td> */}
                                    <td>{sub.make}</td>
                                    <td>{sub.year}</td>
                                    <td>{sub.model}</td>
                                    <td>{sub.partid}</td>
                                    <td>{sub.specification}</td>
                                    <td>{sub.morespecification}</td>
                                    <td>{sub.partType}</td>
                                    <td><button className="btn" onClick={this.deleteSingle.bind(this,sub.id)}>Delete</button></td>
                                    <td><button className="btn" onClick={this.EditSingle.bind(this,sub.id)} data-toggle="modal" data-target="#exampleModalEdit">Edit</button></td>
                                </tr>
                            )}
                        </tbody>
                        </table>
                </div>

                <div class="modal fade" id="exampleModalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                    <form onSubmit={this.onFormSubmitEdit.bind(this)}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add New Part</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Part Id</label>
                                                    <input type="text" value={this.state.partid} onChange={this.getPartId.bind(this)} class="form-control" placeholder="Enter Part Id"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Make Name</label>
                                                    <input type="text" value={this.state.make} onChange={this.getMake.bind(this)} class="form-control" placeholder="Enter Make Name"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Model Name</label>
                                                    <input type="text" value={this.state.model} onChange={this.getModel.bind(this)} class="form-control" placeholder="Enter Model Name"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Year</label>
                                                    <input type="number" value={this.state.year} onChange={this.getYear.bind(this)} class="form-control" placeholder="Enter Year"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Specification</label>
                                                    <textarea class="form-control" value={this.state.specification} onChange={this.getSpecification.bind(this)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Part Type</label>
                                                <select onChange={this.selectPart.bind(this)} class="form-control" id="exampleFormControlSelect1">
                                                <option value={this.state.partType} >{this.state.partType}</option>
                                                    <option value="Engines">Engines</option>
                                                    <option  value="Steering Column">Steering Column</option>
                                                    <option value="Transmission">Transmission</option>
                                                    <option value="A.B.S">A.B.S</option>
                                                    <option value="Axle Assemblies">Axle Assemblies</option>
                                                    <option value="Alternator">Alternator</option>
                                                    <option value="Transfer Case">Transfer Case</option>
                                                    <option value="Struts">Struts</option>
                                                </select>
                                            </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">More Specification</label>
                                                    <textarea class="form-control" value={this.state.morespecification} onChange={this.getMoreSpecification.bind(this)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>

                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                    <form onSubmit={this.onFormSubmit.bind(this)}>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add New Part</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Part Id</label>
                                                    <input type="text" onChange={this.getPartId.bind(this)} class="form-control" placeholder="Enter Part Id"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Make Name</label>
                                                    <input type="text" onChange={this.getMake.bind(this)} class="form-control" placeholder="Enter Make Name"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Model Name</label>
                                                    <input type="text" onChange={this.getModel.bind(this)} class="form-control" placeholder="Enter Model Name"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label>Year</label>
                                                    <input type="number" onChange={this.getYear.bind(this)} class="form-control" placeholder="Enter Year"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">Specification</label>
                                                    <textarea class="form-control" onChange={this.getSpecification.bind(this)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <label for="exampleFormControlSelect1">Part Type</label>
                                                <select onChange={this.selectPart.bind(this)} class="form-control" id="exampleFormControlSelect1">
                                                <option disabled selected="selected" >Choose Part Type</option>
                                                    <option value="Engines">Engines</option>
                                                    <option  value="Steering Column">Steering Column</option>
                                                    <option value="Transmission">Transmission</option>
                                                    <option value="A.B.S">A.B.S</option>
                                                    <option value="Axle Assemblies">Axle Assemblies</option>
                                                    <option value="Alternator">Alternator</option>
                                                    <option value="Transfer Case">Transfer Case</option>
                                                    <option value="Struts">Struts</option>
                                                </select>
                                            </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlTextarea1">More Specification</label>
                                                    <textarea class="form-control" onChange={this.getMoreSpecification.bind(this)} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlFile1">Example file input</label>
                                                    <input type="file" onChange= {this.onChangeFile.bind(this)} class="form-control-file" id="exampleFormControlFile1"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

export default RecentPartManagement;
