import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import engines from '../../../images/ENGINE-01.png';
import steering from '../../../images/stearing-01.png';
import transmission from '../../../images/transmi-01.png';
import abs from '../../../images/abs-01.png';
import axle from '../../../images/axel-01.png';
import alternator from '../../../images/Alternator-01.png';
import transfer from '../../../images/transfer case assembly-01.png';
import struts from '../../../images/sturt-01.png';
import './parts.css';
class Parts extends Component {
  render() {
    return (
        <div className="Parts">
           <div className="container-fluid">
                <div class="row" style={{paddingTop: 20,backgroundColor:'#000'}}>
                    <div class="col-md-12  col-sm-12  col-lg-12">
                    <h2 class="text-center text-white">Featured Genuine OEM Parts</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={engines} alt="Card image cap" />
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>ENGINES</h5>
                            <h6 className="card-text text-white partsDescriptionFont">Driven, Tested and Quality assured low mileage used OEM Engine assemblies for sale at affordable prices. We deliver to your mechanic’s auto shop.</h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-Engines-Assembly', state:{status : 'Engines'} }}  >
                        View Engines
                        </Link>
                        
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={steering} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>STEERING COLUMNS</h5>
                            <h6 className="card-text text-white partsDescriptionFont">Steering
                                is the collection of components, linkages, etc. which allows any vehicle to follow the desired
                                course. Get your quality used steering column now !.</h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-Steering-Assembly', state:{status : 'Steering Column'} }}  >
                        View Steering Columns
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={transmission} alt="Card image cap" />
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>TRANSMISSIONS</h5>
                            <h6 className="card-text text-white partsDescriptionFont">OEM replacement Automatic and Manual Transmissions for all makes and models. OEM quality guaranteed – Tested and quality checked.</h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-Transmission-Assembly', state:{status : 'Transmission'} }}  >
                        View Transmissions
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={abs} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>A.B.S</h5>
                            <h6 className="card-text text-white partsDescriptionFont">Get quality tested used OEM ABS assembly with ABS pump and module for low prices.</h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-ABS-Assembly', state:{status : 'A.B.S'} }}  >
                        View A.B.S
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={axle} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>AXLE ASSEMBLIES</h5>
                            <h6 className="card-text text-white partsDescriptionFont">Hub to hub OEM replacement Axle Assemblies.</h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-Axles-Assembly', state:{status : 'Axle Assemblies'} }}  >
                        View Axle Assemblies
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={alternator} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>ALTERNATORS</h5>
                            <h6 className="card-text text-white partsDescriptionFont">"Own a quality used OEM
                            Alternator with warranty”. Buy used and save upto 70% today
                            </h6>
                        </div>
                        <Link
                        className="btn cardBtn cardBtnParts"
                        to={{ pathname: '/Used-Alternator-Assembly', state:{status : 'Alternator'} }}  >
                        View Alternators
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={transfer} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>TRANSFER CASE</h5>
                            <h6 className="card-text text-white partsDescriptionFont">Quality tested Transfer Case Assemblies available for all make and models.</h6>
                        </div>
                        <Link
                            className="btn cardBtn cardBtnParts"
                            to={{ pathname: '/Used-Transfer-Case-Assembly', state:{status : 'Transfer Case'} }}  >
                            View Transfer Case
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 cardparts">
                        <img className="card-img-top partimage" src={struts} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 style={{color: '#ffbf3c',fontWeight: '600'}}>STRUTS</h5>
                            <h6 className="card-text text-white partsDescriptionFont">High quality used struts with coil spring and shock absorbers. We also provide air struts with air spring.</h6>
                        </div>
                        <Link
                            className="btn cardBtn cardBtnParts"
                            to={{ pathname: '/Used-Transfer-Case-Assembly', state:{status : 'Struts'} }}  >
                            View Struts
                        </Link>
                    </div>
                </div>
            </div>
            {/* <div class="container-fluid">
                <div class="row" style={{paddingTop: 40}}>
                    <div class="col-md-7 offset-md-5 col-sm-7 offset-sm-5 col-lg-7 offset-lg-5">
                    <h3 class="text-center bottom">Most Sold Items</h3>
                    </div>
                </div>
                <div class="row" style={{paddingTop: 30}}>
                    <div class="col-lg-3 col-md-3 col-sm-6" style={{textAlign: 'center'}}>
                    <div class="card" >
                        <img class="card-img-top partimage" style={{marginLeft: '27%'}} src={engines} alt="Card image cap"/>
                        <div class="card-body" style={{backgroundColor: '#E0DFDF'}}>
                        <h5 class="card-title">AXLE </h5>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6" style={{textAlign: 'center'}}>
                    <div class="card" >
                        <img class="card-img-top partimage" style={{marginLeft: '27%'}} src={engines} alt="Card image cap"/>
                        <div class="card-body" style={{backgroundColor: '#E0DFDF'}}>
                        <h5 class="card-title">AXLE </h5>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6" style={{textAlign: 'center'}}>
                    <div class="card" >
                        <img class="card-img-top partimage" style={{marginLeft: '27%'}} src={engines} alt="Card image cap"/>
                        <div class="card-body" style={{backgroundColor: '#E0DFDF'}}>
                        <h5 class="card-title">AXLE </h5>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6" style={{textAlign: 'center'}}>
                    <div class="card" >
                        <img class="card-img-top partimage" style={{marginLeft: '27%'}} src={engines} alt="Card image cap"/>
                        <div class="card-body" style={{backgroundColor: '#E0DFDF'}}>
                        <h5 class="card-title">AXLE </h5>
                        </div>
                    </div>
                    </div>
                </div> */}
                <br></br>
            {/* </div>  */}
        </div>
    );
  }
}
export default Parts;