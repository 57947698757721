import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import axios from 'axios';
import states from './states.json';
import {IP} from './constants';
// import cadillac from './1995 Cadillac Deville 4.9 L (8-300).jpg';
import {Link} from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
class SendMail extends Component {
    constructor(){
        super();
        this.state={
          year : '',
          make : '',
          model : '',
          part : '',
          subpart : '',
          name : '',
          phone : '',
          email : '',
          zip : '',
          location : '',
          state: '',
          notes : '',
          engine : '',
          transmission : '',
          vin : ''
        }
      }
    
      componentWillMount() {
        const {year} = this.props.location.state;
        console.log('nooku',this.props)
        console.log(year)
        const {make} = this.props.location.state;
        const {model} = this.props.location.state;
        const {parts} = this.props.location.state;
        const {subpart} = this.props.location.state;
        this.setState({
          year : year,
          make : make,
          model : model,
          part : parts,
          subpart : subpart
        });
      }
    
      getName(val){
        this.setState({
          name : val.target.value
        });
      }

      getVin(val){
        this.setState({
          vin : val.target.value
        });
      }
    
      getPhone(val){
        this.setState({
          phone : val.target.value
        })
      }
      getEmail(val){
        this.setState({
          email : val.target.value
        })
      }
      getZip(val){
        this.setState({
          zip : val.target.value
        })
      }
    //   getLocation(val){
    //     this.setState({
    //       location : val.target.value
    //     })
    //   }
    
      getState(val){
        this.setState({
          state : val.target.value
        })
      }
      getMsg(val){
        this.setState({
          notes : val.target.value
        })
      }
      getEngine(val){
        this.setState({
        engine :  val.target.value
        })
      }
      getTransmission(val){
        this.setState({
          transmission :  val.target.value
        })
    }

    
      sendMailTo(){
        window.location="/EntryToGet";
        axios.post(IP+'/mailservice',
            {
          'year' : this.state.year === '' ? null : this.state.year,
          'make' : this.state.make === '' ? null : this.state.make,
          'model' : this.state.model === '' ? null : this.state.model,
          'drive' : null,
          'engine' : this.state.engine,
          'part' : this.state.part === '' ? null : this.state.part,
          'interchange' : null,
          'application' : null,
          'name' : this.state.name === '' ? null : this.state.name,
          'phone' : this.state.phone === '' ? null : this.state.phone,
        'email' : this.state.email === '' ? null : this.state.email,
          'text' : null,
          'location' : this.state.location +','+ this.state.state +','+ this.state.zip ,
          'notes' : this.state.notes,
          
            }
          )
          .then( (response) => {
              console.log('enth',response)
            // if(response){
                
            // }
          })
          .catch( (error) => {
            console.log(error);
          });
      }
  render() {
    return (
        <div className="SendMail">
            <Header/>
            <div className="container-fluid">
                <div className="row"
                    style={{backgroundColor: '#DA5252',height: 100}}>
                    <div className="container">
                        <h1 className="text-white" style={{marginTop:'1%'}}><b>Get Quote Now <span style={{fontSize:18}}>(Complete the following fields to get an Instant
Quote)</span></b></h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 col-lg-5 col-md-5 d-md-none d-lg-none">
                      <br></br>
                          <div className="card">
                          <div className="card-header" id="headingOne" style={{background: 'rgb(206, 206, 206)'}}>
                              <h5 className="mb-0">
                              <button className="btn btn-link text-dark" type="button" style={{fontSize:21}}>
                                  Your Auto Part
                              </button>
                              </h5>
                          </div>
                          <div className="card-body" style={{background: 'rgb(245, 245, 245)'}}>
                              <div className="row">
                              <div className="col-sm-12 col-lg-12 col-md-12" style={{fontWeight:'1000', fontSize:24,textAlign:'center'}}>
                                  {this.state.year},{this.state.make},{this.state.model},{this.state.part}
                              </div>
                              </div>
                              <br></br>
                              <div className="row">
                                  <div className="col-sm-12 col-lg-12 col-md-12">
                                      <h5 style={{color:'green',fontStyle:'italic',textAlign:'end'}}><span style={{fontSize: 20,fontWeight: '900'}}>✓</span> In Stock</h5>
                                  </div>
                              </div>
                          </div>
                          </div>
                      </div>
                    <div className="col-sm-12 col-lg-7 col-md-7">
                    <br></br>
                        {/* <div className="card">
                            <div className="card-header" id="headingOne"  style={{background: 'rgba(26,172,75,0.68)'}}>
                                <h5 className="mb-0">
                            <button className="btn btn-link" type="button" style={{fontSize:21,color:'#fff'}}>
                            Complete the following fields to check our inventory
                            </button>
                            </h5>
                            </div>
                        </div>     */}
                        <div className="card">
                        <div className="card-header" id="headingOne"  style={{background: 'rgb(206, 206, 206)'}}>
                            <h5 className="mb-0">
                            <button className="btn btn-link text-dark" type="button" style={{fontSize:21}}>
                                Vehicle Specifications
                            </button>
                            </h5>
                        </div>
                        <div className="card-body" style={{background: 'rgb(245, 245, 245)'}}>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label style={{fontSize:24}}>Engine <span style={{color:'red'}}>*</span></label>
                                    <select style={{fontSize:19}} className="form-control"  onChange={this.getEngine.bind(this)}>
                                        <option disabled selected>Select Engine</option>
                                        <option value="7">3 Cylinder</option>
                                        <option value="1">4 Cylinder</option>
                                        <option value="8">5 Cylinder</option>
                                        <option value="2">6 Cylinder</option>
                                        <option value="3">8 Cylinder</option>
                                        <option value="4">10 Cylinder</option>
                                        <option value="5">12 Cylinder</option>
                                        <option value="10">Other not listed</option>
                                        <option value="6">I'm not sure</option>
                                    </select>
                                </div>
                                <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label style={{fontSize:24}}>Transmission <span style={{color:'red'}}>*</span></label>
                                    <select className="form-control" style={{fontSize:19}} onChange={this.getTransmission.bind(this)}>
                                        <option disabled selected>Select Transmission</option>
                                        <option value="1">2 Wheel Drive, Automatic</option>
                                        <option value="2">4 Wheel Drive, Automatic</option>
                                        <option value="3">2 Wheel Drive, Manual</option>
                                        <option value="4">4 Wheel Drive, Manual</option>
                                        <option value="10">Other not listed</option>
                                        <option value="5">I'm not sure</option>
                                    </select>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label style={{fontSize:24}}>VIN <span style={{fontSize:16,fontStyle:'italic'}}> (Optional)</span></label>
                                    <input type="text" className="form-control" value={this.state.vin} placeholder="Enter your Vehicle Identification Number"
                                    onChange={this.getVin.bind(this)} id="inlineFormInput" ></input>
                                </div>
                            </div>
                        </div>
                        </div>
                        {this.state.transmission === '' || this.state.engine === '' ? '' :
                        
                    
                        <div>
                            <br></br>
                            <h5 style={{color:'#DA5252'}}>We work on a nationwide live inventory with millions of parts which keeps updating. Our Senior Auto Parts Specialists will match your vehicle specifications and assist you with a quote.</h5>
                            <div className="card">
                            <div className="card-header" id="headingOne" style={{background: 'rgb(206, 206, 206)'}}>
                                <h5 className="mb-0">
                                <button className="btn btn-link text-dark" type="button" style={{fontSize:21}}>
                                    For Quote and Assistance
                                </button>
                                </h5>
                            </div>

                                <div className="card-body" style={{background: 'rgb(245, 245, 245)'}}>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>Name <span style={{color:'red'}}>* </span><span style={{fontSize:13}}>(For quote only)</span></label>
                                    <input type="text" className="form-control" value={this.state.name}
                                    onChange={this.getName.bind(this)} id="inlineFormInput" ></input>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>Phone <span style={{color:'red'}}>* </span><span style={{fontSize:13}}>(For quote only)</span></label>
                                    <input type="number" className="form-control" value={this.state.phone}
                                    onChange={this.getPhone.bind(this)} id="inlineFormInput"></input>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>Email <span style={{color:'red'}}>* </span><span style={{fontSize:13}}>(For quote only)</span></label>
                                    <input type="email" className="form-control" value={this.state.email}
                                    onChange={this.getEmail.bind(this)} id="inlineFormInput"></input>
                                    </div>
                                    <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>Zip <span style={{color:'red'}}>* </span><span style={{fontSize:13}}>(For shipping quote)</span></label>
                                    <input type="number" className="form-control" value={this.state.zip}
                                    onChange={this.getZip.bind(this)} id="inlineFormInput"></input>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    {/* <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>Location <span style={{color:'red'}}>*</span></label>
                                    <input type="text" className="form-control" value={this.state.location}
                                    onChange={this.getLocation.bind(this)} id="inlineFormInput"></input>
                                    </div> */}
                                    <div className="col-sm-12 col-lg-6 col-md-6">
                                    <label>State <span style={{color:'red'}}>*</span></label>
                                    <select className="form-control"  onChange={this.getState.bind(this)}>
                                    <option disabled selected>Select state</option>
                                        {states.map((e, key) => {
                                            return <option key={key} value={e.name}>{e.name}</option>;
                                        })}
                                    </select>
                                    </div>
                                </div>
                                </div>
                             </div>   
                        
                        
                        <div className="card">
                        <div className="card-header" id="headingOne"  style={{background: 'rgb(206, 206, 206)'}}>
                            <h5 className="mb-0">
                            <button className="btn btn-link text-dark" type="button" style={{fontSize:21,whiteSpace: 'normal'}}>
                            Describe the <span style={{fontWeight:'1000'}}>{this.state.part}</span> you're looking for.
                            </button>
                            </h5>
                        </div>
                        <div className="card-body" style={{background: 'rgb(245, 245, 245)'}}>
                            <div className="row">
                            <div className="col-sm-12 col-lg-12 col-md-12">
                                <textarea className="form-control" value={this.state.notes} onChange={this.getMsg.bind(this)} id="exampleFormControlTextarea1" 
                                placeholder="Enter any special notes for this part (size, variation)." rows="3"></textarea>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        }
                        <br></br>
                        <div className="row">
                        <div className="col-sm-12 offset-lg-8 col-lg-4 offset-md-8 col-md-4">
                            {this.state.name === '' || this.state.phone === '' 
                            || this.state.zip === '' || this.state.state === '' || this.state.email === ''?
                            <button className="btn cardBtn" style={{width:'100%'}}>Get Me A Price</button>
                            :
                            
                            <button className="btn cardBtn" onClick={this.sendMailTo.bind(this)} style={{width:'100%'}}>Get Me A Price</button>
                            }
                        </div>
                        </div>
                        <br></br>
                    </div>
                    <div className="col-sm-12 col-lg-5 col-md-5 d-none d-md-block">
                    <br></br>
                        <div className="card">
                        <div className="card-header" id="headingOne" style={{background: 'rgb(206, 206, 206)'}}>
                            <h5 className="mb-0">
                            <button className="btn btn-link text-dark" type="button" style={{fontSize:21}}>
                                Your Auto Part
                            </button>
                            </h5>
                        </div>
                        <div className="card-body" style={{background: 'rgb(245, 245, 245)'}}>
                            <div className="row">
                            <div className="col-sm-12 col-lg-12 col-md-12" style={{fontWeight:'1000', fontSize:24,textAlign:'center'}}>
                                {this.state.year},{this.state.make},{this.state.model},{this.state.part}
                            </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-lg-12 col-md-12">
                                    <h5 style={{color:'green',fontStyle:'italic',textAlign:'end'}}><i class="fa fa-check" aria-hidden="true"></i> In Stock</h5>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* <ReCAPTCHA
    sitekey="6Lc-LqEUAAAAAA_qW3niweu_bnUSrUvs2AgCaKES" */}
    {/* // onChange={onChange} */}
  {/* /> */}
            <Footer />    
        </div>
    );
  }
}

export default SendMail;