import React, { Component } from 'react';
import Header from '../scenes/components/header';
import SearchSide from '../scenes/searchSide';
import bg from './bgr.png';
import Footer from '../scenes/components/footer';
import engine from '../images/engines-01.png';
import axios from 'axios';
import {IP} from './constants';
import {Link} from 'react-router-dom';
import Parts from '../scenes/components/home/parts';
class Models extends Component {
    constructor(){
        super();
        this.state = {
            model_name : '',
            make_id : null,
            modelval : [],
            makes : [],
            makeid:null,
            makename:null,
            makelength:10,
            viewLook:0,
            make_name : ''
        }
    }
    componentDidMount(){
        this.setState({
            model_name : this.props.location.state.getmodelname,
            make_name : this.props.location.state.getmakename
        });
        window.scrollTo(0, 0);
        this.getMakeSpecific();
    }
    enterMakes(id, name) {
        this.setState({
            makeid: id,
            makename: name
        });
        window.scrollTo(0, 0);
    }
    changeView() {
        this.setState({
            makelength: 50,
            viewLook: 1
        });
    }
    changeHide() {
        this.setState({
            makelength: 10,
            viewLook: 0
        });
    }
    getMakeSpecific(e) {
        axios.post(IP + '/getMakeSpecific')
            .then((response) => {
                console.log('nioss', response)
                if (response.status === 200) {
                    this.setState({
                        makes: response.data.rows
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="Makes">
                <Header />
                <div className="container-fluid">
                    <div className="row"
                        style={{
                            backgroundColor: '#DA5252', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center',
                            backgroundImage: "url(" + bg + ")", backgroundRepeat: 'repeat',
                            backgroundSize: 145
                        }}>
                        <div className="container">
                            <h1 className="text-white textshadow" style={{ fontSize: '2rem' }}><b>Make</b></h1>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <br></br>
                            <SearchSide makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} view={this.state.view} />
                            <br></br>
                            <div className="d-none d-md-block">
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        <div className="card">
                                            <div className="card-body" style={{ textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                <h3><strong>Select By Make !</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div className="row">
                                    {this.state.makes.slice(0, this.state.makelength).map((make, i) =>
                                        <div key={i} className="col-lg-6 col-md-6 col-sm-6">
                                            <div className="card">
                                                <div onClick={this.enterMakes.bind(this, make.id, make.make_name)} className="card-body" style={{ cursor: 'pointer', height: 51, padding: '0.42rem', background: '#dfe3e6' }}>
                                                    <a style={{ cursor: 'pointer' }}>
                                                        <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)} alt="Card image cap" />
                                                        {/* {this.getImage(make.assets)} */}
                                                        <span style={{ fontWeight: '700', textAlign: 'right', fontSize: 12, textTransform: 'uppercase', color: '#DA5252' }}>{make.make_name}</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br>
                                        </div>
                                    )}
                                </div>

                                <br></br>
                                <div className="row">
                                    <div className="col-md-12 sm-12 col-lg-12">
                                        {this.state.viewLook === 0 ?
                                            <div className="card">
                                                <div onClick={this.changeView.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-down"></i></a>
                                                </div>
                                            </div> :
                                            <div className="card">
                                                <div onClick={this.changeHide.bind(this)} className="card-body" style={{ cursor: 'pointer', textAlign: 'center', height: 54, background: 'rgb(255, 191, 60)', padding: '0.7rem' }}>
                                                    <a style={{ cursor: 'pointer', fontSize: 23 }}><i className="fa fa-angle-double-up"></i></a>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-9 col-md-9">
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                                        <div className="carousel-inner">
                                            <div className="carousel-item active carouselProduct " >
                                                <img className="d-block w-100" src={engine} alt="First slide" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="card" style={{backgroundColor: '#DA5252'}}>
                                        <div className="card-body">
                                            <h5 className="text-white card-title" style={{fontSize: '2rem' }}>USED {this.state.make_name} {this.state.model_name} PARTS</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h6 style={{ fontSize: 14, textAlign: 'justify' }}>
                                    OEM Toyota 4 Runner replacement parts can be expensive. We offer affordable, 
                                    used, OEM Toyota parts that can extend the life of your Toyota! You’ll have access to a 
                                    wide variety of trims and packages parts for the Toyota 4 Runner SR5, Trail, TRD and Limited. 
                                    Continue reading below for a few of the solutions our parts provide.
                                    Buying with Benzeen saves you up to 70% off new and aftermarket prices and takes 
                                    the guesswork out of buying
                                    auto parts! We include with every part, a hassle-free 1 Year Warranty at no extra charge. 
                                    </h6>
                                </div>
                            </div>
                            <br></br>
                            <Parts />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Models;