import React, {Component} from 'react';
import HeaderDashboard from '../dashboard/header/index';
import axios from 'axios';
import {IP} from '../../constants';
class MakeManagement extends Component {
    constructor() {
        super();
    }
    render() {
        return (
            <div className="RecentPartManagement">
                <HeaderDashboard />
                <div className="container-fluid">
                    <div className="row">
                        <div className="container">
                            <div className="col-sm-4 col-lg-4 col-md-4">
                                <br></br>
                                <button type="button" class="btn" style={{backgroundColor:'rgb(255, 191, 60)'}} data-toggle="modal" data-target="#makeModal">
                                    Add New Part
                                </button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Pic</th>
                            <th scope="col">Make</th>
                            <th scope="col">Year</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {this.state.returnData.map((sub,i) =>
                                <tr>
                                    <td>{sub.make}</td>
                                    <td>{sub.year}</td>
                                    <td>{sub.model}</td>
                                    <td><button className="btn">Delete</button></td>
                                    <td><button className="btn" data-toggle="modal" data-target="#makeModalEdit">Edit</button></td>
                                </tr>
                            )} */}
                        </tbody>
                        </table>
                </div>

                
            </div>
        );
    }
}

export default MakeManagement;