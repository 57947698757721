import React, { Component } from 'react';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import bg from './bgr.png';
import Guarantee from './components/guarantee';
class CustomerService extends Component {
    constructor() {
        super();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
  render() {
    return (
        <div className="CustomerService">
            <Header/>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundColor: '#DA5252',height: 100,backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',flex: 1,justifyContent: 'center',alignItems: 'center'}}>
                    <div class="container">
                        <h1 class="text-white"><b>Customer Service</b></h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row"
                    >
                    <div class="container aboutcontent">
                   
                <h4 style={{textAlign:'center',marginTop:'-4%'}}>Having trouble? Call us at <span style={{color:'#A63434'}}><u>
                1.800.608.3868</u></span></h4>
                <br></br> <br></br>
                        <h6>We have a dedicated team of customer service representatives trained to help you with even the minute aspects related to your order. Due to the personal attention we provide each customer we staff this department Monday to Friday, 8.30 am PST to 4.30 pm PST. Our goal is to help you efficiently and in a friendly manner.
                        {/* <span style={{color:'#A63434',fontSize: 34}}>1-800-608-3868</span> */}
                        </h6>
                        <br></br>
                        <h6 style={{textAlign:'center'}}>You can Email us at <br></br><span style={{color:'#A63434'}}><u>customerservice@backtoroadautoparts.com</u></span></h6>
                    </div>  
                </div>
            
                    <div className="col-md-8 offset-md-2 col-sm-12 col-lg-8 offset-lg-2">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#one" aria-expanded="false" aria-controls="one">
                                    What is the procedure for placing an order?
                                    </button>
                                </h5>
                                </div>
                                <div id="one" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    The usual procedure for placing an order would include customers intimating us with requests for auto
                                    parts online or directly contacting us wherein our Senior Auto Parts Specialists, matches all the
                                    specifications of the Auto Part with our catalogues, either with your VIN or by asking some industry
                                    standard relevant questions to narrow down the part. Then the order is again verified by a “Verifier”,
                                    following which we accept payment, sends the invoice to the customer and ships the part out.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#two" aria-expanded="false" aria-controls="two">
                                    If I get a defective or a wrong part, how do I return it?
                                    </button>
                                </h5>
                                </div>
                                <div id="two" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    If you find out that you received a wrong part or a defective one, the first thing you should do is to call
                                    us and if we are not available (out of business hours), send us an Email. Once you intimate us, our
                                    Authorized Personnel will reach you and provide you the guidelines to return the part back and send you
                                    the RMA (Return Merchandise Authorization) form.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#three" aria-expanded="false" aria-controls="three">
                                    How long does it take for the shipment?
                                    </button>
                                </h5>
                                </div>
                                <div id="three" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    Once we get the order from you, your auto part is once again quality checked and inspected and
                                    shipped usually in 24 – 48 hours. For the non-freight auto parts we sell, the average time frame we show
                                    on paper is 5 -7 business days for a free regular shipping, but you might get it sooner as well. For the
                                    freight auto parts we sell, the average time frame is 7 – 10 business days. We also have expedite
                                    options – delivery in 3 business days, 2 business days and 1 business day
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#four" aria-expanded="false" aria-controls="four">
                                    How do I track my shipment?
                                    </button>
                                </h5>
                                </div>
                                <div id="four" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                    We will send you the Shipment Tracking Number to your Email provided at the time of placing the order.
                                    In case you do not have an Email, we will call you to provide the Tracking number.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#five" aria-expanded="false" aria-controls="five">
                                    How can I check my Order Status?
                                    </button>
                                </h5>
                                </div>
                                <div id="five" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                We would be sending you all major updates regarding your order to your Email at the time of placing the order. Additionally, we provide you with a unique Order Number and Reference Number which you can provide to our Customer Service Representative to pull up your Order Details.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed btnCollapseFont" type="button" data-toggle="collapse" data-target="#six" aria-expanded="false" aria-controls="six">
                                    Is it too late to change or cancel my order?
                                    </button>
                                </h5>
                                </div>
                                <div id="six" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                Since most orders begin processing immediately, there is a short window in canceling or changing an order. We will make every effort to accommodate changes  to or cancellations of orders that have not yet processed; However, we are unable to change or cancel an order once it begins processing.
                                If less than an hour has passed since submitting your order, you may either :<br></br> Call Customer Service @ <span style={{color:'#A63434'}}><u>1-800.608.3868</u></span>, opt 2 or Email us at : <span style={{color:'#A63434'}}><u>customerservcie@backtoroadautoparts.com</u></span>
                                </div>
                            </div>
                            </div>
                    </div>        
                </div>
                <br></br>
            
            </div>
            <Guarantee />
            <Footer />    
        </div>
    );
  }
}

export default CustomerService;