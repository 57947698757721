import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './parts.css';
import fast from '../../../images/delivery-01.png';
import secure from '../../../images/secure-01.png';
import support from '../../../images/support-01.png';
import test from '../../../images/tested-01.png';
class HomeQuality extends Component {
  render() {
    return (
        <div className="Parts">
           <div className="container-fluid">
                <div class="row" style={{paddingTop: 20,backgroundColor:'#211d1d'}}>
                    <div class="col-md-12  col-sm-12  col-lg-12">
                        <h2 class="text-center text-white">Quality Recycled Auto Parts</h2>
                    </div>
                </div>
                {/* <br></br> */}
                <div className="row " style={{backgroundColor:'#211d1d'}}>
                    {/* <div className="container"> */}
                        <div className="col-lg-3 col-md-3  col-sm-12">
                            <br></br>
                            <div className="card cardpartstest">
                                <img className="card-img-top partimage imageround" src={test} style={{alignSelf:'center'}} alt="Card image cap" />
                                <div className="card-body">
                                    <h5 style={{color: '#fff',fontWeight: '700'}}>TESTED</h5>
                                </div>
                            </div>
                            <br></br>    
                        </div>
                        <div className="col-lg-3 col-md-3  col-sm-12">
                            <br></br>
                            <div className="card cardpartstest">
                                <img className="card-img-top partimage imageround" src={fast} style={{alignSelf:'center'}} alt="Card image cap" />
                                <div className="card-body">
                                    <h5 style={{color: '#fff',fontWeight: '700'}}>FAST DELIVERY</h5>
                                </div>
                            </div>
                            <br></br>    
                        </div>
                        <div className="col-lg-3 col-md-3  col-sm-12">
                            <br></br>
                            <div className="card cardpartstest">
                                <img className="card-img-top partimage imageround" src={secure} style={{alignSelf:'center'}} alt="Card image cap" />
                                <div className="card-body">
                                    <h5 style={{color: '#fff',fontWeight: '700'}}>SECURE</h5>
                                </div>
                            </div>
                            <br></br>    
                        </div>
                        <div className="col-lg-3 col-md-3  col-sm-12">
                            <br></br>
                            <div className="card cardpartstest">
                                <img className="card-img-top partimage imageround" src={support} style={{alignSelf:'center'}} alt="Card image cap" />
                                <div className="card-body">
                                    <h5 style={{color: '#fff',fontWeight: '700'}}>SUPPORT</h5>
                                </div>
                            </div>
                            <br></br>    
                        </div>
                    {/* </div> */}
                </div>
                {/* <br></br> */}
            </div> 
            
        </div>
    );
  }
}
export default HomeQuality;