import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import SearchMake from './searchMake';
import Guarantee from '../components/guarantee';
import Toyota from '../../images/toyota.jpg';
import gif from '../btr-gif.gif';
class ToyotaManagement extends Component {
    constructor() {
        super();
        this.state = {
            makename: 'Toyota',
            makeid : 103,
            selectedpartid : 1,
            selectedpartname : 'Engine',
            status: true
        }
    }
    componentDidMount() {
        this.setState({
            makename : 'Toyota'
        });
    }
    componentWillMount() {
        this.setState({
            status: false
        });
    }
    render() {
        return (
            <div>
                {
                    this.state.status === true ?
                    <div className="" style={{display:'flex',height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',background:'rgba(0,0,0,0.7)'}}>
                        <img src={gif} style={{height:'13%'}} alt="Card image cap"/>
                    </div>
                    : 
                    (
                        <div>
                            <Header/>
                            <div class="container-fluid">
                                <div class="row"
                                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                                        }}>
                                    <div class="container">
                                        <h1 class="text-white"><b>Used Toyota Engines</b></h1>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 col-md-12">
                                    <h6 style={{textAlign: 'center',fontWeight:'900'}}>
                                        We are nationwide, with warehouses all over USA and Canada. If we do not have your
                                        Toyota Engines in stock, we will locate it for you from our salvage yard partners nationwide.
                                        <br></br>We guarantee a free price quote sent to you in 5 minutes.
                                    </h6>
                                </div>
                                <br></br>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check below if your part is In Stock 
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}} >
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Get Email Quote with pictures of the Toyota Engines
                                    </h6>
                                </div>
                                <div className="col-sm-12 col-md-4 col-lg-4" style={{textAlign:'center',fontWeight:'900'}}>
                                    <h6 style={{textAlign:'center',fontWeight:'900'}}>
                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Check Out and we deliver with Free Shipping 
                                    </h6>
                                </div>
                            </div>
                        </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-md-3">
                                        <br></br>
                                        <SearchMake makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                                        <br></br>
                                    </div>
                                    <div className="col-lg-9 col-sm-9 col-md-9">
                                        <br></br>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <h4><strong><u>Quality Used Toyota Engines</u></strong></h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6 col-lg-6" style={{height:'34vh'}}>
                                                    <img src={Toyota} className="makeImage" />
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6" >
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Road Tested, Performance checked and quality assured Used Toyota Engines
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Quick quotes that save you money
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> Fast delivery to your mechanic
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        <span style={{color:'green',fontSize: 20,fontWeight: '900'}}>✓</span> We service all Toyota models
                                                    </h6>
                                                    <br></br>
                                                    <h6><strong>Buy Quality Toyota Engine Online @ Best Price</strong></h6>
                                                    <br></br>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Toyota is the biggest and the leading Japanese automaker and the fourth largest car manufacturer in the
                                                        United States. It is the market leader in the sales of hybrid electric vehicles and is one of the largest
                                                        companies to encourage mass-market adoption of the hybrid vehicles all over the world.
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        Whether you drive <u>Corolla</u>, <u>Camry</u>, <u>Yaris</u>, <u>Avalon</u>, <u>Sienna</u> or other Toyota model, or you’re a mechanic,
                                                        garage owner or car dealer, BackToRoad Auto Parts has high quality used Toyota parts to suit your
                                                        needs. Our fully stocked facilities carry major Toyota parts like Used Transmissions, Used engines, Used
                                                        alternators,Used wheel,Used Stuts,Used ABS,Used hoods, Used break systems and more. Every product
                                                        is inspected twice before shipping, so you can enjoy peace of mind knowing that your <strong>used Toyota parts</strong>
                                                        &nbsp;are safe and will go the distance.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts are foremost experts in providing fast, reasonably priced and optimally
                                                        functional used Toyota parts. Our enormous inventory of small and large auto parts allows our customers
                                                        to benefit from tremendous savings. Enjoy quick and affordable worldwide shipping, you’re your auto
                                                        parts will arrive at your doorstep in pristine, fully operational condition, ready to be installed in your
                                                        Toyota.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        If you have questions on used Toyota parts, BackToRoad Auto Parts staff of welcoming, knowledgeable
                                                        salespeople can guide you. You won’t have to search high and low for that special part or grapple with
                                                        pricey retailers or dealerships, which saves valuable time and money. In the unlikely event that a part is
                                                        not in stock, we can find it for you and have it delivered wherever you choose.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify'}}>
                                                        BackToRoad Auto Parts can meet all of your used Toyota parts needs with ease. Since 1940, customers
                                                        have turned to us again and again for all of their auto parts and have enjoyed unmatched customer service
                                                        in the process. Check your order status whenever you like, get quick returns and enjoy the security of
                                                        knowing your parts are backed by a One Month Standard Warranty. If by chance your purchase doesn’t
                                                        meet your needs,BackToRoad Auto Parts provides a replacement part or a full refund of the purchase
                                                        price plus shipping fees. It’s risk free.
                                                    </h6>
                                                    <h6 style={{textAlign: 'justify',color:'rgb(218, 82, 82)'}}>
                                                    Get your used Toyota parts from a reliable auto parts seller today, call us at 1-800-608-3868
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <Guarantee/>
                            <Footer />
                        </div>
                    )
                }  
            </div>
        );
    }
}

export default ToyotaManagement;