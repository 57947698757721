import React, { Component } from 'react';
import './searchSide.css';
import axios from 'axios';
import {IP} from './constants';
import Header from '../scenes/components/header';
import Footer from '../scenes/components/footer';
import SearchSide from './searchSide';
import {Link} from 'react-router-dom';
import bg from './bgr.png';
import Guarantee from './components/guarantee';
class AllParts extends Component {
  constructor(props){
    super(props);
    this.state={
      yearval : [],
      makeval : [],
      modelval : [],
      partval : [] ,
      subpartval : [],
      partlocal : '',
      partLength : 100,
      showview: true,
      partvalsearch : '',
      searchLength : 0,
      selectedpartname : '',
      selectedpartid : null,
      yearBlink : 0,
      makes : [],
      makelength : 10,
      viewLook : 0,
      makeid : null,
      makename:null
    }
  }
  // componentWillMount(){
  //   window.location = 'about.html';

  // }
  componentDidMount () {
    this.getAllPart();
    this.getMakeSpecific();
    window.scrollTo(0, 0);
  }

  getAllPart(e){

    axios.post(IP+'/parts/')
      .then( (response) => {
        if (response.status === 200) {
          this.setState({
            partval : response.data,
            modelSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  viewall() {
    this.setState({
      partLength : this.state.partval.length,
      showview : false
    });
  }

  hideall() {
    this.setState({
      partLength : 50,
      showview : true
    });
  }

  searchPart(e){
    let val = e.target.value.toLowerCase();
    let matches = this.state.partval.filter(v => v.part_name.toLowerCase().includes(val));
    this.setState({
      searchLength : e.target.value.length,
      partvalsearch : matches
    })
  }

  enterPart(id, name){
    this.setState({
      selectedpartid:id,
      selectedpartname: name,
      yearBlink : 1
    });
    window.scrollTo(0, 0);
  }

  openMechanical(type){
    axios.post(IP+'/parts/mechanical/'+type)
      .then( (response) => {
        console.log('kanil',response)
        if (response.status === 200) {
          this.setState({
            partval : response.data,
            modelSelected : 1
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  getMakeSpecific(e) {
    axios.post(IP+'/getMakeSpecific')
      .then( (response) => {
          console.log('nioss',response)
        if (response.status === 200) {
          this.setState({
              makes : response.data.rows
          });
        }
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  enterMakes(id,name){
    this.setState({
      makeid : id,
      makename : name
    }); 
    window.scrollTo(0, 0);
  }

    changeView(){
      this.setState({
        makelength:50,
        viewLook : 1
      });
    }
    changeHide(){
      this.setState({
        makelength:10,
        viewLook : 0
      });
    }
  render() {

    return (
          <div>
            <Header/>
            <div class="container-fluid">
                <div class="row"
                    style={{backgroundColor: '#DA5252',height: 100,flex: 1,justifyContent: 'center',alignItems: 'center'
                        }}>
                    <div class="container">
                        <h1 class="text-white"><b>PARTS</b></h1>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-sm-12 col-md-3">
                    <br></br>
                        <SearchSide makeid={this.state.makeid} makename={this.state.makename} yearBlink={this.state.yearBlink} partid={this.state.selectedpartid} partname={this.state.selectedpartname}/>
                        <br></br>
                        <div className="row d-none d-md-block">
                          <div className="col-md-12 sm-12 col-lg-12">
                            <div className="card">
                              <div className="card-body" style={{textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <h3><strong><u>Select By Make !</u></strong></h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row d-none d-md-block">  
                          {this.state.makes.slice(0, this.state.makelength).map((make,i) =>
                          <div key={i} className="col-lg-6 col-md-6 col-sm-12">
                              <div className="card">
                                <div onClick={this.enterMakes.bind(this,make.id,make.make_name)} className="card-body" style={{cursor:'pointer',height:51,padding:'0.42rem',background:'#dfe3e6'}}>
                                  <a   style={{cursor:'pointer'}}>
                                    <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)}  alt="Card image cap" />
                                    {/* {this.getImage(make.assets)} */}
                                    <span style={{fontWeight:'700',textAlign:'right',fontSize:12, textTransform: 'uppercase', color:'#DA5252'}}>{make.make_name}</span>
                                  </a>
                                </div>
                              </div>
                              <br></br>
                          </div>
                      )}
                        </div>
                        <br></br>
                        <div className="row d-none d-md-block">
                          <div className="col-md-12 sm-12 col-lg-12">
                            {this.state.viewLook === 0 ?
                            <div className="card">
                              <div onClick={this.changeView.bind(this)} className="card-body" style={{cursor:'pointer',textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <a  style={{cursor:'pointer', fontSize:23}}><i class="fa fa-angle-double-down"></i></a>
                              </div>
                            </div>  :
                            <div className="card">
                              <div onClick={this.changeHide.bind(this)} className="card-body" style={{cursor:'pointer',textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <a  style={{cursor:'pointer', fontSize:23}}><i class="fa fa-angle-double-up"></i></a>
                              </div>
                            </div>
                          }
                          </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-sm-12 col-md-9">
                    <br></br>
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className="nav-item nav-link taber active" onClick={this.getAllPart.bind(this)}  id="nav-home-tab" data-toggle="tab" href="#all-parts" role="tab" aria-controls="all-parts" aria-selected="true">All Parts</a>
                                <a className="nav-item nav-link taber" onClick={this.openMechanical.bind(this,1)} id="nav-profile-tab"  data-toggle="tab" href="#mechanical-parts" role="tab" aria-controls="mechanical-parts" aria-selected="false">Mechanical Parts</a>
                                <a className="nav-item nav-link taber" onClick={this.openMechanical.bind(this,11)} id="nav-contact-tab"  data-toggle="tab" href="#electrical-parts" role="tab" aria-controls="electrical-parts" aria-selected="false">Electrical Parts</a>
                                <a className="nav-item nav-link taber" onClick={this.openMechanical.bind(this,8)} id="nav-contact-tab"  data-toggle="tab" href="#body-parts" role="tab" aria-controls="body-parts" aria-selected="false">Body Parts</a>
                                <a className="nav-item nav-link taber" onClick={this.openMechanical.bind(this,16)} id="nav-contact-tab"  data-toggle="tab" href="#mc" role="tab" aria-controls="mc" aria-selected="false">Modules & Computers</a>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="all-parts" role="tabpanel" aria-labelledby="all-parts-tab">
                                <div className="container-fluid">
                                  <br></br>
                                  <div className="row">
                                      <div className="col-sm-12 col-lg-6 col-md-6">
                                      <input style={{borderRadius:0}} type="text" className="form-control" placeholder="Search your part" onChange={this.searchPart.bind(this)} ></input>
                                      </div>
                                  </div>
                                  <br></br>
                                  <p>We carry huge stock of Used Auto Parts in
our store. Auto Body Parts stock comprises of Engines, Transmissions, Doors, Axles ,

Bumpers, ABS, shock absorbers, front grills, Steering columns, roofs and a countless more
OEM Used Auto and Truck Parts. We have in our stock auto and truck parts of all popular
models including AMC, Acura, Ford, BMW, Dodge etc. Let’s get you Back To Road</p>
                                  <br></br>
                                  <div className="row">
                                      {this.state.searchLength === 0 ?
                                      this.state.partval.slice(0, this.state.partLength).map((part,i) =>
                                      <div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}} >
                                      
                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                      </div>) :
                                      this.state.partvalsearch.map((part,i) =>
                                      <div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder"  style={{padding:4}}>
                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                      </div>)
                                      }
                                  </div>
                                  <br></br>
                                  <div className="row">
                                    <div className="col-sm-12 offset-lg-5 col-lg-7 offset-md-5 col-md-7">
                                    {this.state.searchLength === 0 ?
                                    this.state.showview === true ?
                                        <button className="btn" onClick={this.viewall.bind(this)} style={{background:'#ffbf3c',color:'black'}}>View All</button>
                                        :
                                        <button className="btn" onClick={this.hideall.bind(this)} style={{background:'#ffbf3c',color:'black'}}>Hide</button>
                                    : ''}
                                  </div>
                                </div>
                            </div>
                        </div>
                          <div className="tab-pane fade" id="mechanical-parts" role="tabpanel" aria-labelledby="mechanical-parts-tab">
                              <div className="container-fluid">
                                      <br></br>
                                      <p>Our inventory is updated with all
the major mechanical parts to get you Back To Road. We carry parts and accessories of
Suspension System, Engine Assembly, Transmission Parts, Cooling and Heating System
Steering, Brakes and a lot more. All the parts we carry are OEM (Original Equipment
Manufacturer) parts that are quality tested, checked and comes with warranty. We have in
our stock auto and truck parts of all popular models including AMC, Acura, Ford, BMW,
Dodge etc</p>
                                  <br></br>
                                      <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Cooling and Heating</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("2") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "2" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Engine</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("3") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "3" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Transmission</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("4") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "4" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Axles</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("5") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "5" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Suspension</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("6") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "6" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Steering</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("7") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "7" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Brakes</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                        {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("13") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "13" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" >
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                            </div>
                          </div>
                        <div className="tab-pane fade" id="electrical-parts" role="tabpanel" aria-labelledby="electrical-parts-tab">
                            <div className="container-fluid">
                                            <br></br>
                                            <p>Be it a motor or a regulator or a switch, we carry it all.
With us, you can find all the major Electrical Parts for your car or truck including wiper
motor, window regulator, lights, wiper transmission, door motor, temperature controls etc
and a lot more. We carry OEM electrical parts for all major brands like Dodge, Ford, GM,
Audi BMW etc with warranty. These are tested and checked before it is shipped out.</p>
                                  <br></br>
                                      <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Lights</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("12") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "12" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Interior Parts</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("14") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "14" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Electricals</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("15") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "15" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="body-parts" role="tabpanel" aria-labelledby="body-parts-tab">
                          <div className="container-fluid">
                                  <br></br>
                                  <p>All the major OEM body parts for you truck or car is made
available in our huge inventory. Listing all major body parts such as Bumpers, fenders,
Quarter Panels, Doors etc. All Glass parts such as door glass, quarter glass etc for your
vehicle is made available.</p>
                                  <br></br>
                                      <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Body</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("9") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "9" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Glass</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("10") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "10" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="mc" role="tabpanel" aria-labelledby="mc-tab">
                          <div className="container-fluid">
                                  <br></br>
                                  <p>Finding it hard to get that computer module ?
Leave the burden to us, with our access to all major Engine and Chassis Control Modules,
you are surely in the right place. We match the part numbers of your modules and provide
you the unit with warranty. All parts we sell are OEM equipment’s and exact fits for your
vehicle. Call us today to get your vehicle Back To Road</p>
                                  <br></br>
                                      <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Engine Control Module</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("17") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "17" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                                  <br></br>
                                  <div className="container-fluid">
                                        <div className="row" >
                                          <div className="col-sm-12 col-md-12 col-lg-12" >
                                            <h4><strong>Chassis Control Modules</strong></h4>
                                            <br></br>
                                          </div>
                                        </div>
                                        <div className="row">
                                            {this.state.partval.map((part,i) =>
                                            {
                                              if(part.subtype.includes(",")){
                                                var splites = part.subtype;
                                                var splited = splites.split(",");
                                                return (
                                                    part.subtype.indexOf("18") > -1 ?
                                                      (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                        <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                      </div>) :''
                                                  )
                                              } else {
                                                return (
                                                      part.subtype === "18" ?
                                                        (<div key={i} className="col-sm-6 col-md-4 col-lg-4 partborder" style={{padding:4}}>
                                                          <a onClick={this.enterPart.bind(this,part.id,part.part_name)} style={{fontSize : 16,color:'#000',cursor:'pointer'}} >{part.part_name}</a>
                                                        </div>) :''
                                                    )
                                              }
                                            }
                                            ) 
                                            }
                                        </div>
                                      </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    
                </div>
            </div>  
            <div className="container-fluid">
            <div className="row d-lg-none d-md-none">
                          <div className="col-md-12 sm-12 col-lg-12">
                            <div className="card">
                              <div className="card-body" style={{textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <h3><strong><u>Select By Make !</u></strong></h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row d-lg-none d-md-none">  
                          {this.state.makes.slice(0, this.state.makelength).map((make,i) =>
                          <div key={i} className="col-lg-6 col-md-6 col-sm-12">
                              <div className="card">
                                <div onClick={this.enterMakes.bind(this,make.id,make.make_name)} className="card-body" style={{cursor:'pointer',height:51,padding:'0.42rem',background:'#dfe3e6'}}>
                                  <a   style={{cursor:'pointer'}}>
                                    <img className="card-img-top partimageMakemake" src={require(`./components/home/${make.assets}`)}  alt="Card image cap" />
                                    {/* {this.getImage(make.assets)} */}
                                    <span style={{fontWeight:'700',textAlign:'right',fontSize:12, textTransform: 'uppercase', color:'#DA5252'}}>{make.make_name}</span>
                                  </a>
                                </div>
                              </div>
                              <br></br>
                          </div>
                      )}
                        </div>
                        <br></br>
                        <div className="row d-lg-none d-md-none">
                          <div className="col-md-12 sm-12 col-lg-12">
                            {this.state.viewLook === 0 ?
                            <div className="card">
                              <div onClick={this.changeView.bind(this)} className="card-body" style={{cursor:'pointer',textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <a  style={{cursor:'pointer', fontSize:23}}><i class="fa fa-angle-double-down"></i></a>
                              </div>
                            </div>  :
                            <div className="card">
                              <div onClick={this.changeHide.bind(this)} className="card-body" style={{cursor:'pointer',textAlign:'center',height: 54,background:'rgb(255, 191, 60)', padding: '0.7rem'}}>
                                  <a  style={{cursor:'pointer', fontSize:23}}><i class="fa fa-angle-double-up"></i></a>
                              </div>
                            </div>
                          }
                          </div>
                        </div>
            </div>    
            <Guarantee />  
            <Footer />
          </div>
    );
  }
}

export default AllParts;