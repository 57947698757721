import React, { Component } from 'react';
import  img from '../../../components/logo.png';
import {Link} from 'react-router-dom';
// import '../../../components/header.css';
class HeaderDashboard extends Component {
    constructor(){
        super();
        this.state={
            active : 0
        }
    }
    
  render() {
    return (
      <div className="Header">
        <nav className="navbar navbar-light bg-light bg-white">
            <div className="col-lg-5 offset-lg-5">
                <h1 className="navbar-brand ">
                    <Link to="/">
                        <img src={img} alt="BackToRoad logo"/>
                    </Link>
                </h1>
            </div>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor:'#DA5252'}}>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarNav">
                <ul className="navbar-nav container">
                    <li className="nav-item " className={this.state.active===0 ? '' : ''}>
                        <Link className="nav-link" to="/"><h6 className="text-white" >Dashboard</h6></Link>
                    </li>
                    <li className="nav-item" className={this.state.active===1 ? '' : ''}>
                        <Link className="nav-link text-black" to="/RecentPartManagement"><h6 className="text-white" >Parts Management</h6></Link>
                    </li>
                    <li className="nav-item" className={this.state.active===1 ? '' : ''}>
                        <Link className="nav-link text-black" to="/MakeManagement"><h6 className="text-white" >Make Management</h6></Link>
                    </li>
                </ul>
            </div>
        </nav>
      </div>
    );
  }
}
export default HeaderDashboard;
