import React, { Component } from 'react';
import  img from './logo.png';
import {Link} from 'react-router-dom';
// import './header.css';

import f1 from './f1-01.png';
import f2 from './f2-01.png';
import f3 from './f3-01.png';
import axios from 'axios';
import {IP} from '../constants';
import mc from './mcafee.jpeg';
import payment from '../../images/payment-method-icons1-01.png';
import secured from '../../images/secured.png';
import discover from '../../images/discover.png';
import amex from '../../images/amex.png';
import master from '../../images/mastercard.png';
import visa from '../../images/visa.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
class Footer extends Component {
    constructor(props){
        super(props);
        this.state={
            makes : [],
            
        }
    }
    componentDidMount () {
        this.getMakeSpecific();
      }
    getMakeSpecific(e) {
        axios.post(IP+'/getMakeSpecific')
          .then( (response) => {
              console.log('nioss',response)
            if (response.status === 200) {
              this.setState({
                  makes : response.data.rows
              })
              localStorage.setItem('allmakes',this.state.makes)
            }
          })
          .catch( (error) => {
            console.log(error);
          });
      }
      enterMakes(id,name){ 
        //  window.location="/";
        window.scrollTo(0, 0);
        this.props.onSelectMakeFoot(id,name);
      }
  render() {
    return (
        <div className="Footer">
            <img src={discover} style={{display:'none'}} />
                        <img className="contactUsimg" alt="Card image cap" src={amex} className style={{display:'none'}}/>
                        <img className="contactUsimg" alt="Card image cap" src={master} style={{display:'none'}}/>
                        <img className="contactUsimg" alt="Card image cap" src={visa} style={{display:'none'}}/>
            <div class="container-fluid" style={{paddingTop: 30,backgroundColor: '#3A3939'}}>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 offset-lg-1">
                        <h4 class="underLineFooter" style={{color: '#fff'}}>Popular Brands</h4>
                        
                        <div className="row">
                            {this.state.makes.slice(0,16).map((make,i) =>
                                <div key={i} className="col-lg-3 col-md-3 col-sm-4">
                                    <Link to={{pathname: `/make/${make.make_name}`, state: { getmakeid: make.id,getmakename:make.make_name }}}  style={{cursor:'pointer',color:'white',fontSize:14}}>
                                        {make.make_name}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 popularBrandMargin">
                        <h4 class="underLineFooter" style={{color: '#fff'}}>Quick Links</h4>   
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <Link style={{color:'white',fontSize:14,marginLeft:10}} to="/">Home</Link>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <Link style={{color:'white',fontSize:14,marginLeft:10}} to="/About">About</Link>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <Link style={{color:'white',fontSize:14,marginLeft:10}} to="/Parts">Parts</Link>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <Link style={{color:'white',fontSize:14,marginLeft:10}} to="/Warranty">Warranty</Link>
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                            <Link style={{color:'white',fontSize:14,marginLeft:10}} to="/CustomerService">Customer Service</Link>
                            </div> 
                        </div> 
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        <h4 class="underLineFooter" style={{color: '#fff'}}>Contact Us</h4>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div className="row" style={{marginTop:5}}>
                                    <img className="contactUsimg"  src={f1} alt="Card image cap"/>
                                    <h5 className="contactUsFont">
                                        Serving USA and Canada
                                    </h5>
                                </div>
                                <div className="row" style={{marginTop:5}}>
                                    <img className="contactUsimg" src={f2} alt="Card image cap"/>
                                    <a href="tel:18006083868" style={{color:'#fff'}} className="contactUsFont">
                                        1.800.608.3868
                                    </a>
                                </div>
                                <div className="row" style={{marginTop:5}}>
                                    <img className="contactUsimg" src={f3} alt="Card image cap"/>
                                    <h5 className="contactUsFont">
                                    customerservice@backtoroadautoparts.com
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="row" style={{paddingTop: 30}}>
                <div class="col-lg-10 offset-lg-1  col-md-10 col-sm-12">
                <h4 class="underLineFooter" style={{color: '#fff'}}>About BackToRoad Auto Parts</h4>
                <h6 style={{color:'white',fontSize:14,textAlign:'justify'}}>
                    We deliver quality used parts to all 50 states and 5 self-governing
                    territories of USA and all over Canada. We work on a live inventory with auto parts
                    updated instantly according to availability, so we can ensure a fast shipment. With our
                    tie-up shipping carriers all over the country, even if the parts you are looking for is not
                    available nearby you or in your state, we ensure quick delivery to your residence or to your mechanic.
                    Every Auto Part that we provide are used Original Equipment Manufacturer (OEM) parts which are quality checked
                    to be in A grade condition. Our Senior Parts Specialists with over 20 years of combined experience in the industry,
                    work round the clock to help you with finding the exact
                    auto part you are looking for by matching the specifications of your vehicle and by decoding your VIN number.
                </h6>
                </div>
            </div>
            </div>

            <div class="container-fluid">
                <div class="row">
                    <div className="col-sm-12 col-md-2 col-lg-2" style={{background:'rgb(51, 50, 50)'}}>
                    &nbsp;
                    </div>
                    <div class="col-lg-4 col-md-4  col-sm-12" style={{backgroundColor: '#333232'}}>
                        <h5 style={{color: '#fff',paddingTop: 20}}>SECURED BY</h5>
                        <a href="https://www.mcafeesecure.com/verify?host=backtoroadautoparts.com&popup=1" target="_blank">
                            <img class="card-img-top" style={{width: '17%',paddingBottom: 20}} src={mc} alt="Card image cap"/>
                        </a>
                        <a target="_blank" href="https://seal.godaddy.com/verifySeal?sealID=CVqTZsFywP7SmHVTenDE7UZsrlXajEJjBK6ABoV43HC1Jsgmof967R2x2dx7">
                            <img class="card-img-top" style={{width: '30%',paddingBottom: 20,cursor:'pointer',marginLeft:'2%'}}  src={secured} alt="Card image cap"/>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12" style={{backgroundColor: '#333232'}}>
                    <h5 className="weAcceptFont" style={{color: '#fff',paddingTop: 20,marginLeft: '2%'}}>WE ACCEPT</h5>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-sm-12 center" >
                                    {/* <img className="card-img-top partimagepay" src={payment}   alt="Card image cap" /> */}
                                    <LazyLoadImage
                                    alt="1"
                                    src={payment}
                                    width="100%" 
                                    height={45} />
                                </div>
                            </div>
                        </div>
                        {/* <img class="card-img-top" style={{width: '27%',paddingBottom: 20}} src="/resources/images/index/mcafee.jpeg" alt="Card image cap"/> */}
                    </div>
                    {/* // <div class="col-lg-4 col-md-6 col-sm-12" style={{backgroundColor: '#333232',textAlign: 'center'}}>
                    //     <h5 style={{color: '#fff',paddingTop: 20}}>SECURED BY</h5>
                    //     <img class="card-img-top" style={{width: '27%',paddingBottom: 20,}} src="/resources/images/index/mcafee.jpeg" alt="Card image cap"/>
                    // </div> */}
                </div>
            </div>  
            <div className="container-fluid">
                    <div className="row" style={{backgroundColor: 'rgb(33, 33, 33)',textAlign: 'center'}}>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="container">
                            <br></br>
                                <h6 style={{color:'#fff',fontSize:10}}>This website is not endorsed, approved or affiliated with any of the makes that may be represented by their respective names or logos. None of the owners of these names or logos represent any of the products advertised or offered on this website. All make names and logos are property of their respective owners and are used in this website solely for identification and education.</h6>
                            </div>
                        </div>
                    </div>
            </div>
            <div className="container-fluid">
                    <div className="row" style={{backgroundColor: 'rgb(27, 25, 25)',textAlign: 'center'}}>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="container">
                            <br></br>
                                <h6 style={{color:'#fff',fontSize:10}}>© 2019 BackToRoad Auto Parts LLC. All Rights Reserved.</h6>
                            </div>
                        </div>
                    </div>
            </div>
              
        </div>
    );
  }
}
export default Footer;