import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import a from './slider1.png';
import './slide.css';
import Search from './search';
import Imagess from '../../../images/new.webp';
import Slide1 from '../../../images/map22-01-compressor.webp';
import Slide2 from '../../../images/map-01-compressor.webp';
import Slide3 from '../../../images/slide3.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
class Slide extends Component {
    constructor(props){
        super(props);
        this.state = {
            first : 'carousel-item carouselHome active',
            second : 'carousel-item carouselHome'
        }
    }
    componentDidMount(){
    //     this.timeoutHandle = setTimeout(()=>{
    //         this.setState({
    //             first : 'carousel-item carouselHome',
    //             second : 'carousel-item carouselHome active'
    //         });
    //    }, 6000);
    }
  render() {
    return (
        // <div className="Slider">
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel" data-interval="6000" >
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                </ol>
                <div className="carousel-inner">
                    <div className={this.state.first }>
                        <LazyLoadImage
                            alt="1"
                            src={Slide2}
                            width="100%" style={{paddingTop: '7%'}}  />
                    </div>
                    <div className={this.state.second }>
                        {/* <img className="d-block w-100" src={Imagess} alt="First slide" /> */}
                        <LazyLoadImage
                            alt="1"
                            src={Imagess}
                            width="100%"  />
                    </div>
                    <div className="carousel-item carouselHome">
                        {/* <img className="d-block w-100" src={Slide1} alt="First slide" /> */}
                        <LazyLoadImage
                            alt="1"
                            src={Slide1}
                            width="100%" style={{paddingTop: '3%'}} />
                    </div>
                    <div className="carousel-item carouselHome">
                    <LazyLoadImage
                            alt="1"
                            src={Slide3}
                            width="100%" />
                    </div>
                </div>
                <Search makeid={this.props.makeid} makename={this.props.makename}/>
            </div>
        // </div>
    );
  }
}
export default Slide;